
.outcomeView {
    padding: 50px;
}

.outcomeView-img {
    width: 100%;
}

.co-mapping {
    display: flex;
    margin-top: 30px;
}

.co-mapping-text > h5 {
    font-weight: bold;
}

.mapping-description > p {
    text-align: justify;
}

.coMapping-img {
    width: 100%;
        box-shadow: 0px 0px 40px rgba(171, 67, 87, 0.2);

}

.outcome-features-header {
    text-align: center;
    background-color: #184FA0;
    color: #FFFFFF;
    padding-top: 20px;
}

.outcome-features-header > h4 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
}

.outcome-features-list {
    background-color: #184FA0;
    padding: 30px;
    color: #FFFFFF;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.outcome-feature {
    width: 23%;
    padding: 40px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    border: 0.8px solid #FFFFFF;
    box-shadow: 0px 0px 20px rgba(171, 67, 87, 0.2);
    border-radius: 5px;
    text-align: center;
    justify-content: center;
}

.outcome-feature > p {
    margin-bottom: 0 !important;
}

.clarifiedCurriculum {
    display: flex;
    flex-direction: column;
    padding: 50px;
    text-align: center;
    width: 33%;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.clarifiedCurriculum > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.clarifiedCurriculum > p {
    text-align: justify;
}

.clarifiedCurriculum-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.teachingFocus {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.teachingFocus > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.teachingFocus > p {
    text-align: justify;
}

.teachingFocus-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.robustFramework {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.robustFramework > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.robustFramework > p {
    text-align: justify;
}

.robustFramework-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.benchmarkAchievement {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.benchmarkAchievement > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.benchmarkAchievement > p {
    text-align: justify;
}

.benchmarkAchievement-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.standardAchievement {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.standardAchievement > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.standardAchievement > p {
    text-align: justify;
}

.standardAchievement-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.accreditationPreparation {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.accreditationPreparation > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.accreditationPreparation > p {
    text-align: justify;
}

.accreditationPreparation-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 540px) {
    .outcomeView {
        padding: 20px;
    }

    .co-mapping {
        flex-direction: column;
    }

    .co-mapping-text {
        padding: 0;
    }
    
    .coMapping-image {
        padding: 0;
    }
    
    .outcome-features-header > h4 {
        font-size: 18px;
    }

    .outcome-feature {
        width: 100%;
    }

    .clarifiedCurriculum {
        width: 100%;
        margin-right: 0;
    }

    .teachingFocus {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .robustFramework {
        width: 100%;
        margin-left: 0;
    }

    .benchmarkAchievement {
        width: 100%;
        margin-right: 0;
    }

    .standardAchievement {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .accreditationPreparation {
        width: 100%;
        margin-left: 0;
    }
}
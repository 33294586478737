
.mentoringView {
    padding: 100px;
}

.mentoringView-img {
    width: 100%;
}

.my-mentees {
    display: flex;
    margin-top: 70px;
}

.my-mentees-text > h5 {
    font-weight: bold;
}

.myMentees-img {
    width: 100%;
}

.meetings {
    display: flex;
    margin-top: 70px;
}

.meetings-text > h5 {
    font-weight: bold;
}

.meetings-img {
    width: 100%;
}

.mentees-discussion {
    display: flex;
    margin-top: 70px;
}

.mentees-discussion-text > h5 {
    font-weight: bold;
}

.menteesDiscussion-img {
    width: 100%;
    height: 700px;
}

.practicalAdvice {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.practicalAdvice > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.practicalAdvice > p {
    text-align: justify;
}

.practicalAdvice-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.developCommunication {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.developCommunication > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.developCommunication > p {
    text-align: justify;
}

.developCommunication-img {
    width: 40px;
    margin-bottom: 10px;
}

.strategyDevelopment {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.strategyDevelopment > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.strategyDevelopment > p {
    text-align: justify;
}

.strategyDevelopment-img {
    width: 40px;
    margin-bottom: 10px;
}

/* .paperless {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.paperless-text > h5 {
    font-weight: bold;
}

.paperless-img {
    height: 40px;
    margin-right: 10px;
}

.transparent {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.transparent-text > h5 {
    font-weight: bold;
}

.transparent-img {
    height: 40px;
    margin-right: 10px;
}

.fourAccess {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.fourAccess-text > h5 {
    font-weight: bold;
}

.fourAccess-img {
    height: 40px;
    margin-right: 10px;
} */

@media only screen and (max-width: 540px) {
    .mentoringView {
        padding: 20px;
    }

    .my-mentees {
        flex-direction: column;
    }
    
    .my-mentees-text {
        padding: 0;
    }
    
    .myMentees-image {
        padding: 0;
    }

    .meetings {
        flex-direction: column-reverse;
    }
    
    .meetings-text {
        padding: 0;
    }
    
    .meetings-image {
        padding: 0;
    }

    .mentees-discussion {
        flex-direction: column;
    }
    
    .mentees-discussion-text {
        padding: 0;
    }

    .menteesDiscussion-img {
        height: auto;
    }
    
    .menteesDiscussion-image {
        padding: 0;
    }

    .practicalAdvice {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .developCommunication {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .strategyDevelopment {
        padding: 10px;
        width: 100%;
        border-right: none;
    }

}

.smsView {
    padding: 100px;
}

.smsView-img {
    /* width: 100%; */
    max-height: 500px;
}
.online-sms {
    display: flex;
    margin-top: 70px;
}

.online-sms-text > h5 {
    font-weight: bold;
}

.onlineSMS-img {
    /* width: 100%; */
    max-height: 500px;
}

.custom-sms {
    display: flex;
    margin-top: 70px;
}

.custom-sms-text > h5 {
    font-weight: bold;
}

.customSMS-img {
    width: 100%;
}

.onePlaceSolution {
    display: flex;
    flex-direction: column;
    padding: 50px;
    text-align: center;
    width: 33%;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.onePlaceSolution > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.onePlaceSolution > p {
    text-align: justify;
}

.onePlaceSolution-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.SMSPortal {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.SMSPortal > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.SMSPortal > p {
    text-align: justify;
}

.SMSPortal-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.instantSMS {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.instantSMS > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.instantSMS > p {
    text-align: justify;
}

.instantSMS-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.effectiveCost {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.effectiveCost > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.effectiveCost > p {
    text-align: justify;
}

.effectiveCost-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.manageContact {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.manageContact > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.manageContact > p {
    text-align: justify;
}

.manageContact-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.senderName {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.senderName > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.senderName > p {
    text-align: justify;
}

.senderName-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 540px) {
    .smsView {
        padding: 20px;
    }

    .onlineSMS-img {
        width: 100%;
        max-height: 500px;
    }
    
    .smsView-img {
        width: 100%;
        max-height: 500px;
    }
    .online-sms {
        margin-top: 30px;
        flex-direction: column;
    }

    .online-sms-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .onlineSMS-image {
        padding: 0;
    }

    .custom-sms {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .custom-sms-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .customSMS-image {
        padding: 0;
    }

    .onePlaceSolution {
        width: 100%;
        margin-right: 0;
    }

    .SMSPortal {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .instantSMS {
        width: 100%;
        margin-left: 0;
    }

    .effectiveCost {
        width: 100%;
        margin-right: 0;
    }

    .manageContact {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .senderName {
        width: 100%;
        margin-left: 0;
    }
}




.facultyProfileView {
    padding: 100px;
}

.facultyProfileView-img {
    width: 100%;
}

.certifications {
    display: flex;
    margin-top: 30px;
}

.certifications-text > h5 {
    font-weight: bold;
}

.certifications-img {
    width: 100%;
}

.experience {
    padding: 30px;
    display: flex;
}

.experience-text > h5 {
    font-weight: bold;
}

.experience-img {
    width: 100%;
}

.achievements {
    padding: 30px;
    display: flex;
    background-color: #F2F8FF;
}

.achievements-text > h5 {
    font-weight: bold;
}

.achievements-img {
    width: 100%;
}

.affiliation {
    padding: 30px;
    display: flex;
    background-color: #F2F8FF;
}

.affiliation-text > h5 {
    font-weight: bold;
}

.affiliation-img {
    width: 100%;
}

.facultyHub {
    display: flex;
    flex-direction: column;
    padding: 50px;
    text-align: center;
    width: 33%;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.facultyHub > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.facultyHub > p {
    text-align: justify;
}

.facultyHub-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.customProfile {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.customProfile > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.customProfile > p {
    text-align: justify;
}

.customProfile-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.accreditationStandard {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.accreditationStandard > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.accreditationStandard > p {
    text-align: justify;
}

.accreditationStandard-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.allTimeAccess {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.allTimeAccess > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.allTimeAccess > p {
    text-align: justify;
}

.allTimeAccess-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.profileRepository {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.profileRepository > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.profileRepository > p {
    text-align: justify;
}

.profileRepository-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.records {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.records > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.records > p {
    text-align: justify;
}

.records-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 540px) {
    .facultyProfileView {
        padding: 20px;
    }

    .certifications {
        flex-direction: column;
    }

    .certifications-text {
        padding: 0;
    }
    
    .certifications-image {
        padding: 0;
    }

    .experience {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .experience-text {
        padding: 0;
    }

    .experience-image {
        padding: 0;
    }

    .achievements {
        flex-direction: column;
        padding: 20px;
    }

    .achievements-image {
        padding: 0;
    }

    .achievements-text {
        padding: 0;
    }

    .affiliation {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .affiliation-image {
        padding: 0;
    }

    .affiliation-text {
        padding: 0;
    }

    .facultyHub {
        width: 100%;
        margin-right: 0;
    }

    .customProfile {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .accreditationStandard {
        width: 100%;
        margin-left: 0;
    }

    .allTimeAccess {
        width: 100%;
        margin-right: 0;
    }

    .profileRepository {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .records {
        width: 100%;
        margin-left: 0;
    }

}

.implementations-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.customer-message {
    text-align: center;
}

.customer-message > h1 {
    background-image: linear-gradient(to right,#293B79,#0028B4);
    font-weight: bold;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.customer-text {
    text-align: center;
    padding: 10px 350px;
}

.customer-text > h6 {
    text-align: justify;
}

.implementations-container {
    padding: 20px 30px;
}

.pain-areas {
    display: flex;
}

.painAreas-img {
    width: 100%;
}

.pain-areas-text > h5 {
    font-weight: bold;
}

.line1-img {
    padding-top: 50px;
    padding-right: 200px;
    width: 80%;
}

.line2-img {
    padding-top: 50px;
    padding-left: 200px;
    margin-top: 70px;
    width: 80%;
}

.training-plan {
    display: flex;
    margin-top: 40px;
}

.trainingPlan-img {
    width: 80%;
}

.training-plan-text > h5 {
    font-weight: bold;
}

.successful-training {
    display: flex;
}

.successfulTraining-img {
    /* width: 100%; */
    max-height: 500px;
}

.successful-training-text > h5 {
    font-weight: bold;
}

.single-contact {
    display: flex;
    margin-top: 40px;
}

.singleContact-img {
    width: 80%;
}

.single-contact-text > h5 {
    font-weight: bold;
}

.help-desk {
    display: flex;
}

.helpDesk-img {
    width: 100%;
}

.help-desk-text > h5 {
    font-weight: bold;
}

.contactDetails {
    text-align: center;
}

.contactDetails > h5 {
    font-weight: bold;
}

.contactButton {
    background: #13B1F4;
    color: #FFFFFF;
    padding: 6px 12px;
    box-shadow: 0px 0px 10px rgba(19, 177, 244, 0.5);
    border-radius: 10px;
}

.contactButton:hover {
    background: #13B1F4;
    color: #FFFFFF;
}

@media only screen and (max-width: 540px) {

    .helpDesk-img {
        width: 80%;
    }
    .successfulTraining-img {
        width: 100%;
        max-height: 500px;
    }
    .implementations-header {
        padding-top: 100px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .customer-message > h1 {
        font-size: 22px;
    }

    .customer-text {
        padding: 20px;
    }

    .implementations-container {
        padding: 20px;
    }

    .line1-img {
        display: none;
    }

    .line2-img {
        display: none;
    }

    .pain-areas {
        flex-direction: column-reverse;
    }

    .painAreas-image {
        padding: 0;
    }

    .pain-areas-text {
        padding: 0;
    }

    .training-plan {
        flex-direction: column;
    }

    .trainingPlan-image {
        padding: 0;
    }

    .training-plan-text {
        padding: 0;
    }

    .successful-training {
        flex-direction: column-reverse;
    }

    .successfulTraining-image {
        padding: 0;
    }

    .successful-training-text {
        padding: 0;
    }

    .single-contact {
        flex-direction: column;
    }

    .singleContact-image {
        padding: 0;
    }

    .single-contact-text {
        padding: 0;
    }

    .help-desk {
        flex-direction: column-reverse;
    }

    .helpDesk-image {
        padding: 0;
    }

    .help-desk-text {
        padding: 0;
    }

    .contactDetails {
        padding: 20px;
    }
}

.privacy-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.privacy-info {
    text-align: center;
    margin-bottom: 20px;
}

.privacy-info > h1 {
    font-weight: bold;
}

.privacy-text {
    padding: 20px 30px;
}

.privacy-text > h5 {
    color: #0A7E00;
    text-decoration: underline;
}

.privacy-policyList {
    padding: 0px 30px;
    display: flex;
    margin-top: 20px;
}

.privacy-img {
    width: 100%;
    height: 500px;
}

.policy {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.policy > h6 {
    text-align: justify;
}

.policyList-policy {
    padding: 0px 30px;
}

.privacy-benefits {
    padding: 20px 30px;
    background-color: #184FA0;
}

.storeData {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.storeData-text > h5 {
    font-weight: bold;
}

.storeData-text > p {
    text-align: justify;
}

.storeData-img {
    height: 40px;
    margin-right: 10px;
}

.thirdParty {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.thirdParty-text > h5 {
    font-weight: bold;
}

.thirdParty-text > p {
    text-align: justify;
}

.thirdParty-img {
    height: 40px;
    margin-right: 10px;
}

.dataRetention {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.dataRetention-text > h5 {
    font-weight: bold;
}

.dataRetention-text > p {
    text-align: justify;
}

.dataRetention-img {
    height: 40px;
    margin-right: 10px;
}

.disclosure {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.disclosure-text > h5 {
    font-weight: bold;
}

.disclosure-text > p {
    text-align: justify;
}

.disclosure-img {
    height: 40px;
    margin-right: 10px;
}

.changes {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.changes-text > h5 {
    font-weight: bold;
}

.changes-text > p {
    text-align: justify;
}

.changes-img {
    height: 40px;
    margin-right: 10px;
}

.questions {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.questions-text > h5 {
    font-weight: bold;
}

.questions-text > p {
    text-align: justify;
}

.questions-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {

    .privacy-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .privacy-info > h1 {
        font-size: 22px;
    }

    .privacy-text {
        padding: 20px;
    }

    .privacy-policyList {
        padding: 0px 20px;
        flex-direction: column-reverse;
    }

    .privacy-image {
        padding: 0;
    }

    .privacy-img {
        height: 300px;
    }

    .privacyList {
        padding: 0;
    }

    .policyList-policy {
        padding: 0px 20px;
    }

    .privacy-benefits {
        padding: 20px;
    }
}


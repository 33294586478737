
.admissionView {
    padding: 100px;
}

.admissionView-img {
    max-height: 500px;
}

.admission-enquiry {
    display: flex;
    margin-top: 30px;
}

.admission-enquiry-text > h5 {
    font-weight: bold;
}

.admissionEnquiry-img {
    width: 100%;
}

.enquiry-status {
    padding: 30px;
    display: flex;
}

.enquiry-status-text > h5 {
    font-weight: bold;
}

.enquiryStatus-img {
    width: 100%;
}

.registration {
    padding: 30px;
    display: flex;
    background-color: #F2F8FF;
}

.registration-text > h5 {
    font-weight: bold;
}

.registration-img {
    width: 100%;
}

.extendBoundaries {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.extendBoundaries > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.extendBoundaries > p {
    text-align: justify;
}

.extendBoundaries-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.automationProcess {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.automationProcess > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.automationProcess > p {
    text-align: justify;
}

.automationProcess-img {
    width: 40px;
    margin-bottom: 10px;
}

.userFriendly {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.userFriendly > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.userFriendly > p {
    text-align: justify;
}

.userFriendly-img {
    width: 40px;
    margin-bottom: 10px;
}

.keepTrack {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.keepTrack-text > h5 {
    font-weight: bold;
}

.keepTrack-img {
    height: 40px;
    margin-right: 10px;
}

.oneTimeSetup {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.oneTimeSetup-text > h5 {
    font-weight: bold;
}

.oneTimeSetup-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {
    .admissionView {
        padding: 20px;
    }
    .admissionView-img {
        max-height: 500px;
        width: 100%;
    }
    .employee-registration {
        margin-top: 30px;
        flex-direction: column;
    }

    .admission-enquiry {
        flex-direction: column;
    }

    .admission-enquiry-text {
        padding: 0;
    }
    
    .admissionEnquiry-image {
        padding: 0;
    }

    .enquiry-status {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .enquiry-status-text {
        padding: 0;
    }

    .enquiryStatus-image {
        padding: 0;
    }

    .registration {
        flex-direction: column;
        padding: 20px;
    }

    .registration-image {
        padding: 0;
    }

    .registration-text {
        padding: 0;
    }

    .extendBoundaries {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .automationProcess {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .userFriendly {
        padding: 10px;
        width: 100%;
        border-right: none;
    }

}

.studentProfileView {
    padding: 100px;
}

.studentProfileView-img {
    width: 100%;
}

.students-profile {
    display: flex;
    margin-top: 70px;
}

.student-profile-text > h5 {
    font-weight: bold;
}

.studentProfile-img {
    width: 100%;
}

.accessQuickly {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.accessQuickly > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.accessQuickly > p {
    text-align: justify;
}

.accessQuickly-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.digitalRegister {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.digitalRegister > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.digitalRegister > p {
    text-align: justify;
}

.digitalRegister-img {
    width: 40px;
    margin-bottom: 10px;
}

.cloudStorage {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.cloudStorage > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.cloudStorage > p {
    text-align: justify;
}

.cloudStorage-img {
    width: 40px;
    margin-bottom: 10px;
}

.unlimitedStorage {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.unlimitedStorage-text > h5 {
    font-weight: bold;
}

.unlimitedStorage-img {
    height: 40px;
    margin-right: 10px;
}

.dataGlance {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.dataGlance-text > h5 {
    font-weight: bold;
}

.dataGlance-img {
    height: 40px;
    margin-right: 10px;
}

.everyAccess {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.everyAccess-text > h5 {
    font-weight: bold;
}

.everyAccess-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {
    .studentProfileView {
        padding: 20px;
    }

    .students-profile {
        margin-top: 30px;
        flex-direction: column;
    }

    .student-profile-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .studentProfile-image {
        padding: 0;
    }
    
    .accessQuickly {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .digitalRegister {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .cloudStorage {
        padding: 10px;
        border-right: none;
        width: 100%;
    }

}

.infoLink-Header {
    background-color: #EDEDED;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.infoLink {
    margin-right: 20px;
    font-weight: bold;
}

.infoLink-title {
    color: #007bff !important;
    text-decoration: underline !important;
}

.contactLink {
    font-weight: bold;
    margin-right: 20px;
}

.contactLink > a:hover {
    color: #007bff !important;
}

.navigation-menu {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 100;
    border-bottom: 1px solid #D3D3D3;
}

.navigationButton {
    display: none;
}

.navigationMenu {
    display: none;
}

.companyName {
    font-size: 24px;
    float: left;
    font-weight: bold;
    margin-left: 20px;
}

.registered {
    font-size: 20px;
}

.registered-logo {
    top: -15px !important;
}

.company-title {
    color: #1C39BB !important;
    cursor: pointer;
    padding-left: 0;
    display: flex;
}

.allMenu {
    float: right;
    padding-right: 0; 
    display: flex;
    justify-content: space-between;
}

.link-container {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

#dropdownMenuButton {
    cursor: pointer;
    margin-right: 20px;
}

.mega-menu-full {
    position: static;
}

.productMenu {
    width: 100%;
    margin-top: 6px;
    transform: translate3d(0, 80px, 0) !important;
}

.mega-menu-content {
    position: fixed;
    background-color: #FFFFFF;
    width: 100%;
    border-bottom: 1px solid #D3D3D3;
}

.successMenu {
    padding: 0;
    width: 200px;
    margin-top: 9px;
    border: 1px solid #D3D3D3;
}

.success-item {
    border-bottom: 1px solid #C4C4C4;
    padding: 10px;
}

.success-item:last-child {
    border-bottom: none;
}
 
.resourceMenu {
    padding: 0;
    width: 200px;
    margin-top: 9px;
    border: 1px solid #D3D3D3;
}

.resource-item {
    border-bottom: 1px solid #C4C4C4;
    padding: 10px;
}

.resource-item:last-child {
    border-bottom: none;
}

.aboutMenu {
    padding: 0;
    width: 200px;
    right: -40px !important;
    margin-top: 9px;
    left: initial !important;
    border: 1px solid #D3D3D3;
}

.about-item {
    border-bottom: 1px solid #C4C4C4;
    padding: 10px;
}

.about-item:last-child {
    border-bottom: none;
}

.outcome-education-link {
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.academic-software-link {
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.office-automation-link {
    display: flex;
    align-items: center;
    padding-top: 30px;
}

.outcome-education-link > a:hover {
    color: #007bff !important;
}

.academic-software-link > a:hover {
    color: #007bff !important;
}

.office-automation-link > a:hover {
    color: #007bff !important;
}

.academic-menu {
    padding: 20px;
    border-left: 1px solid #C4C4C4;
}

.academic-menu > a:hover {
    color: #007bff !important;
}

.academic-menu > a > p {
    margin-bottom: 0;
}

.admin-menu {
    padding: 20px;
    border-left: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
}

.admin-menu > a:hover {
    color: #007bff !important;
}

.admin-menu > a > p {
    margin-bottom: 0;
}

.accounting-menu {
    padding: 20px;
}

.accounting-menu > a:hover {
    color: #007bff !important;
}

.accounting-menu > a > p {
    margin-bottom: 0;
}

.student-menu {
    padding: 20px;
}

.student-menu > a:hover {
    color: #007bff !important;
}

.student-menu > a > p {
    margin-bottom: 0;
}

#dropdownMenuButton > a:hover {
    color: #007bff !important;
}
.enquiry-button {
    font-family: Arial;
    color: #ffffff;
    font-size: 15px;
    background: #0b112f;
    padding: 5px 20px 5px 20px;
    border: solid #ffffff 2px;
    text-decoration: none;
    margin-top: 8px;
}

.enquiryButtonDiv {
    height: 0px;
    width: 84px;
    position: fixed;
    right: 0;
    top: 60%;
    z-index: 1000;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}
@media screen and (max-width: 540px) {
    .navigation-menu {
        padding-top: 30px;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: flex-start;
    }

    .allMenu {
        display: none;
    }

    .navigationButton {
        display: block;
    }

    .navigationMenu {
        display: inherit;
        border-bottom: 2px solid #D3D3D3;
        padding-top: 90px;
        z-index: 50;
        background-color: white;
        width: 100%;
        position: fixed;
        overflow-y: scroll;
        height: 100%;
        flex-direction: column;
    }

    .company-title {
        text-align: center;
        padding-left: 45px;
    }

    .infoLink {
        font-size: 14px;
    }

    .contactLink {
        font-size: 14px;
    }

    .companyName {
        font-size: 18px;
        margin-left: 0;
    }

    .registered {
        font-size: 20px;
    }

    .registered-logo {
        top: -8px !important;
    }

    #dropdownMenuButton {
        font-size: 16px;
        margin-right: 0;
    }

    .toggle-button {
        font-size: 18px !important;
        padding: 8px 15px !important;
        box-shadow: none !important;
    }

    .nav-dropdown > span {
        padding: 10px;
        font-size: 16px !important;
        font-weight: bold;
    }

    .company-title {
        padding-left: 0px;
    }

    .mega-menu-content {
        border-bottom: none;
        position: static;
    }

    .productMenu {
        transform: translate3d(0px, 0px, 0) !important;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        height: 100%;
        overflow: auto;
    }

    .outcome-education-link {
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .academic-software-link {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .office-automation-link {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .academic-menu  {
        border-left: none;
    }

    .admin-menu  {
        border-left: none;
    }

    .successMenu {
        margin-top: 0;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .resourceMenu {
        margin-top: 0;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .aboutMenu {
        right: 0 !important;
        left: 0 !important;
        margin-top: 0;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .outcome-menu {
        font-size: 14px;
    }

    .inventory-menu {
        font-size: 14px;
    }

    .academic-menu {
        font-size: 14px;
    }

    .admin-menu {
        font-size: 14px;
    }

    .accounting-menu {
        font-size: 14px;
    }

    .student-menu {
        font-size: 14px;
    }

    .about-item {
        border-bottom: none;
        padding: 0px 20px !important;
    }
}
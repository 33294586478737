
.resultView {
    padding: 100px;
}

.resultView-img {
    max-height: 500px;
    /* width: 100%; */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.schedule-examination {
    display: flex;
    margin-top: 30px;
}

.schedule-examination-text > h5 {
    font-weight: bold;
}

.scheduleExamination-img {
    width: 100%;
}

.question-paper {
    padding: 30px;
    display: flex;
}

.question-paper-text > h5 {
    font-weight: bold;
}

.questionPaper-img {
    width: 100%;
}

.performance {
    padding: 30px;
    display: flex;
    background-color: #F2F8FF;
}

.performance-text > h5 {
    font-weight: bold;
}

.performance-img {
    /* width: 100%; */
    max-height: 350px;
}

.result {
    padding: 30px;
    display: flex;
}

.resultData-text > h5 {
    font-weight: bold;
}

.result-img {
    width: 100%;
}

.benefits-container2 {
    padding: 30px;
    display: flex;
    background-color: #FFF6F9;
}

.quickBenefits4 {
    padding: 20px 30px;
    background-color: #FFF6F9;
}

.quickBenefits4-header {
    display: flex;
    align-items: center;
}

.quickBenefits4-header > h3{
    font-weight: bold;
    color: #AB4357;
}

.depthAnalysis {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.depthAnalysis > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.depthAnalysis > p {
    text-align: justify;
}

.depthAnalysis-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.studentPerformance {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.studentPerformance > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.studentPerformance > p {
    text-align: justify;
}

.studentPerformance-img {
    width: 40px;
    margin-bottom: 10px;
}

.difficultyAnalysis {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.difficultyAnalysis > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.difficultyAnalysis > p {
    text-align: justify;
}

.difficultyAnalysis-img {
    width: 40px;
    margin-bottom: 10px;
}

.longTerm-benefits3 {
    padding: 20px 30px;
    background-color: #FFF6F9;
}

.longTerm-benefits3-header {
    display: flex;
    align-items: center;
}

.longTerm-benefits3-header > h3{
    font-weight: bold;
    color: #AB4357;
}

@media only screen and (max-width: 540px) {
    .resultView {
        padding: 20px;
    }
    .performance-img {
        width: 100%;
        max-height: 275px;
    }
    .resultView-img {
        max-height: 500px;
        width: 70%;
    }

    .schedule-examination {
        flex-direction: column;
    }

    .schedule-examination-text {
        padding: 0;
    }
    
    .scheduleExamination-image {
        padding: 0;
    }

    .question-paper {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .question-paper-text {
        padding: 0;
    }

    .questionPaper-image {
        padding: 0;
    }

    .performance {
        flex-direction: column;
        padding: 20px;
    }

    .performance-image {
        padding: 0;
    }

    .performance-text {
        padding: 0;
    }

    .result {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .resultData-text {
        padding: 0;
    }

    .result-image {
        padding: 0;
    }

    .benefits-container2 {
        padding: 20px;
        flex-direction: column;
    }

    .quickBenefits4 {
        padding: 20px;
    }

    .depthAnalysis {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .studentPerformance {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .difficultyAnalysis {
        padding: 10px;
        width: 100%;
        border-right: none;
    }
    
    .longTerm-benefits3 {
        padding: 20px;
    }

}
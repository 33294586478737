
.academicPlanningView {
    padding: 100px;
}

.academicPlanningView-img {
    width: 100%;
}

.features-container1 {
    background-color: #F2F8FF;
    padding: 30px;
}

.manage-course {
    display: flex;
    margin-top: 30px;
}

.manage-course-text > h5 {
    font-weight: bold;
}

.manageCourse-img {
    width: 100%;
}

.manage-faculty {
    padding: 30px;
    display: flex;
}

.manage-faculty-text > h5 {
    font-weight: bold;
}

.manageFaculty-img {
    width: 100%;
}

.course-syllabus {
    padding: 30px;
    display: flex;
    background-color: #F2F8FF;
}

.course-syllabus-text > h5 {
    font-weight: bold;
}

.courseSyllabus-img {
    width: 100%;
}

.meetIntegration {
    background-color: #184FA0;
}

.meet-header {
    padding: 20px 30px;
    color: #FFFFFF;
}

.meet-header > h2 {
    font-weight: bold;
}

.meet-image {
    padding: 20px 30px;
}

.meet-img {
    width: 100%;
}

.transparency {
    display: flex;
    flex-direction: column;
    padding: 50px;
    text-align: center;
    width: 33%;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.transparency > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.transparency > p {
    text-align: justify;
}

.transparency-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.outcomeOnGo {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.outcomeOnGo > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.outcomeOnGo > p {
    text-align: justify;
}

.outcomeOnGo-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.numerousReports {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.numerousReports > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.numerousReports > p {
    text-align: justify;
}

.numerousReports-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.accreditation {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.accreditation > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.accreditation > p {
    text-align: justify;
}

.accreditation-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.customization {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.customization > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.customization > p {
    text-align: justify;
}

.customization-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.greenSupport {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.greenSupport > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.greenSupport > p {
    text-align: justify;
}

.greenSupport-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 540px) {
    .academicPlanningView {
        padding: 20px;
    }

    .academicPlanningView-img {
        height: auto;
    }

    .features-container1 {
        padding: 20px;
    }

    .manage-course {
        flex-direction: column;
    }

    .manage-course-text {
        padding: 0;
    }
    
    .manageCourse-image {
        padding: 0;
    }

    .manage-faculty {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .manage-faculty-text {
        padding: 0;
    }

    .manageFaculty-image {
        padding: 0;
    }

    .course-syllabus {
        flex-direction: column;
        padding: 20px;
    }

    .courseSyllabus-image {
        padding: 0;
    }

    .course-syllabus-text {
        padding: 0;
    }

    .transparency {
        width: 100%;
        margin-right: 0;
    }

    .outcomeOnGo {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .numerousReports {
        width: 100%;
        margin-left: 0;
    }

    .accreditation {
        width: 100%;
        margin-right: 0;
    }

    .customization {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .greenSupport {
        width: 100%;
        margin-left: 0;
    }

}

.autonomyView {
    padding: 100px;
}

.autonomyView-img {
    width: 100%;
}

.schedule-assessment {
    display: flex;
    margin-top: 70px;
}

.schedule-assessment-text > h5 {
    font-weight: bold;
}

.scheduleAssessment-img {
    width: 100%;
}

.set-paper {
    display: flex;
    margin-top: 70px;
}

.set-paper-text > h5 {
    font-weight: bold;
}

.setPaper-img {
    width: 100%;
}

.assign-assessment {
    display: flex;
    margin-top: 70px;
}

.assign-assessment-text > h5 {
    font-weight: bold;
}

.assignAssessment-img {
    width: 100%;
}

.add-performance {
    display: flex;
    margin-top: 70px;
}

.add-performance-text > h5 {
    font-weight: bold;
}

.addPerformance-img {
    width: 100%;
}

.autonomyResult {
    display: flex;
    margin-top: 70px;
}

.autonomyResult-text > h5 {
    font-weight: bold;
}

.autonomyResult-img {
    width: 100%;
}

.streamProcess {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.streamProcess > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.streamProcess > p {
    text-align: justify;
}

.streamProcess-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.microscopy {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.microscopy > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.microscopy > p {
    text-align: justify;
}

.microscopy-img {
    width: 40px;
    margin-bottom: 10px;
}

.safe {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.safe > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.safe > p {
    text-align: justify;
}

.safe-img {
    width: 40px;
    margin-bottom: 10px;
}

.go-green {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.go-green-text > h5 {
    font-weight: bold;
}

.go-green-img {
    height: 40px;
    margin-right: 10px;
}

.trackRecord {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.trackRecord-text > h5 {
    font-weight: bold;
}

.trackRecord-img {
    height: 35px;
    margin-right: 15px;
}

.oneSolution {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.oneSolution-text > h5 {
    font-weight: bold;
}

.oneSolution-img {
    height: 35px;
    margin-right: 15px;
}

@media only screen and (max-width: 540px) {
    .autonomyView {
        padding: 20px;
    }

    .schedule-assessment {
        margin-top: 30px;
        flex-direction: column;
    }

    .schedule-assessment-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .scheduleAssessment-image {
        padding: 0;
    }

    .set-paper {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .set-paper-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .setPaper-image {
        padding: 0;
    }

    .assign-assessment {
        margin-top: 30px;
        flex-direction: column;
    }

    .assign-assessment-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .assignAssessment-image {
        padding: 0;
    }

    .add-performance {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .add-performance-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .addPerformance-image {
        padding: 0;
    }

    .autonomyResult {
        margin-top: 30px;
        flex-direction: column;
    }

    .autonomyResult-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .autonomyResult-image {
        padding: 0;
    }
    
    .benefits-info {
        flex-direction: column;
    }

    .benefits-header > h3 {
        font-size: 24px;
    }

    .benefits-data {
        margin-top: 30px;
    }

    .streamProcess {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
    }

    .microscopy {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
    }

    .safe {
        padding: 10px;
        width: 100%;
        border-right: none;
    }

    .valueSaving {
        width: 100%;
        margin-right: 0;
    }

    .lowStock {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .precisePlanning {
        width: 100%;
        margin-left: 0;
    }
}




.presence-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.presence-info {
    text-align: center;
    margin-bottom: 20px;
}

.presence-info > h1 {
    font-weight: bold;
    background: linear-gradient(to right, #0648F3, #07D10F);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.presenceList-container {
    padding: 170px;
}

.presenceList {
    display: flex;
    flex-wrap: wrap;
}

.presence {
    padding: 80px 20px;
    text-align: center;
    width: 21%;
    background: rgba(255, 255, 255, 0.1);
    border: 4px solid #FFFFFF;
    border-radius: 10px;
    margin: 20px;
}

.presence > h6 {
    color: #FFFFFF;
    margin-bottom: 0;
}

@media only screen and (max-width: 540px) {

    .presence-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .presence-info > h1 {
        font-size: 22px;
    }

    .presenceList-container {
        padding: 20px;
        background-size: 100% 100%;
    }

    .presence {
        width: 100%;
    }
}

.certificateView {
    padding: 100px;
}

.certificateView-img {
    width: 100%;
}

.custom-certificate {
    display: flex;
    margin-top: 70px;
}

.custom-certificate-text > h5 {
    font-weight: bold;
}

.customCertificate-img {
    width: 100%;
}

.certificate-request {
    display: flex;
    margin-top: 70px;
}

.certificate-request-text > h5 {
    font-weight: bold;
}

.certificateRequest-img {
    width: 100%;
}

.predefinedTemplate {
    display: flex;
    flex-direction: column;
    padding: 50px;
    text-align: center;
    width: 33%;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.predefinedTemplate > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.predefinedTemplate > p {
    text-align: justify;
}

.predefinedTemplate-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.transparent-process {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.transparent-process > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.transparent-process > p {
    text-align: justify;
}

.transparent-process-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.approvedWorkflow {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.approvedWorkflow > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.approvedWorkflow > p {
    text-align: justify;
}

.approvedWorkflow-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.processOptimization {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.processOptimization > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.processOptimization > p {
    text-align: justify;
}

.processOptimization-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.recordsOnGo {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.recordsOnGo > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.recordsOnGo > p {
    text-align: justify;
}

.recordsOnGo-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.percentDigital {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.percentDigital > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.percentDigital > p {
    text-align: justify;
}

.percentDigital-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 540px) {
    .certificateView {
        padding: 20px;
    }

    .custom-certificate {
        margin-top: 30px;
        flex-direction: column;
    }

    .custom-certificate-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .customCertificate-image {
        padding: 0;
    }

    .certificate-request {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .certificate-request-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .certificateRequest-image {
        padding: 0;
    }

    .predefinedTemplate {
        width: 100%;
        margin-right: 0;
    }

    .transparent-process {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .approvedWorkflow {
        width: 100%;
        margin-left: 0;
    }

    .processOptimization {
        width: 100%;
        margin-right: 0;
    }

    .recordsOnGo {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .percentDigital {
        width: 100%;
        margin-left: 0;
    }
}




.feedbackView {
    padding: 100px;
}

.feedbackView-img {
    width: 100%;
}

.feedback-type {
    display: flex;
    margin-top: 70px;
}

.feedback-type-text > h5 {
    font-weight: bold;
}

.feedbackType-img {
    /* width: 100%; */
    max-height: 500px;
}

.feedback-question {
    display: flex;
    margin-top: 70px;
}

.feedback-question-text > h5 {
    font-weight: bold;
}

.feedbackQuestion-img {
    max-height: 500px;
}

.conduct-feedback {
    display: flex;
    margin-top: 70px;
}

.conduct-feedback-text > h5 {
    font-weight: bold;
}

.conductFeedback-img {
      max-height: 650px;

}

.feedback-analysis {
    display: flex;
    margin-top: 70px;
}

.feedback-analysis-text > h5 {
    font-weight: bold;
}

.feedbackAnalysis-img {
    width: 100%;
}

.satisfaction {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.satisfaction > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.satisfaction > p {
    text-align: justify;
}

.satisfaction-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.instantDecision {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.instantDecision > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.instantDecision > p {
    text-align: justify;
}

.instantDecision-img {
    width: 40px;
    margin-bottom: 10px;
}

.improveApproval {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.improveApproval > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.improveApproval > p {
    text-align: justify;
}

.improveApproval-img {
    width: 40px;
    margin-bottom: 10px;
}

.paperless {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.paperless-text > h5 {
    font-weight: bold;
}

.paperless-img {
    height: 40px;
    margin-right: 10px;
}

.transparent {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.transparent-text > h5 {
    font-weight: bold;
}

.transparent-img {
    height: 40px;
    margin-right: 10px;
}

.fourAccess {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.fourAccess-text > h5 {
    font-weight: bold;
}

.fourAccess-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {
    .feedbackView {
        padding: 20px;
    }
    .feedbackType-img {
        width: 100%;
        max-height: 500px;
    }
    .feedbackQuestion-img {
        max-height: 500px;
        width: 100%;
    }
    .feedback-type {
        flex-direction: column;
    }
    
    .feedback-type-text {
        padding: 0;
    }
    
    .feedbackType-image {
        padding: 0;
    }

    .feedback-question {
        flex-direction: column-reverse;
    }
    
    .feedback-question-text {
        padding: 0;
    }
    
    .feedbackQuestion-image {
        padding: 0;
    }

    .conduct-feedback {
        flex-direction: column;
    }
    
    .conduct-feedback-text {
        padding: 0;
    }

    .conductFeedback-img {
        height: auto;
    }
    
    .conductFeedback-image {
        padding: 0;
    }
    
    .feedback-analysis {
        flex-direction: column-reverse;
    }
    
    .feedback-analysis-text {
        padding: 0;
    }
    
    .feedbackAnalysis-image {
        padding: 0;
    }

    .satisfaction {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .instantDecision {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .improveApproval {
        padding: 10px;
        width: 100%;
        border-right: none;
    }

}
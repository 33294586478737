
.company-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.company-message {
    padding: 15px;
    display: flex;
}

.company-text {
    display: flex;
    align-items: center;
}

.company-text > h1 {
    font-weight: bold;
    background: linear-gradient(#0A5AD1, #790795);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.modernize-img {
    width: 60%;
}

.companyInfo-container {
    background-color: #5C63AB;
    color: #FFFFFF;
}

.company-question {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 200px;
}

.company-question > h6 {
    text-align: justify;
}

.core-values {
    display: flex;
    padding: 15px;
}

.coreValues-list {
    padding-right: 150px;
}

.coreValues-img {
    /* width: 100%; */
    max-height: 300px;
}

@media only screen and (max-width: 540px) {
    .coreValues-img {
        width: 100%;
        max-height: 300px;
    }
    .company-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .company-message {
        flex-direction: column;
        padding: 5px;
    }

    .company-text > h1 {
        font-size: 22px;
    }

    .company-text {
        text-align: center;
    }

    .company-question {
        padding: 20px;
    }

    .core-values {
        padding: 5px;
        flex-direction: column;
    }

    .coreValues-list {
        padding-right: 15px;
    }
}
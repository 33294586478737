
.grievanceView {
    padding: 100px;
}

.grievanceView-img {
    /* width: 100%; */
    max-height: 500px;
}

.grievance-features-container {
    padding: 30px;
}

.grievanceList-image {
    padding: 20px 100px;
}

.grievanceList-img {
    width: 100%;
}

.grievanceInterface-container {
    display: flex;
    padding: 30px;
    background-color: #184FA0;
}

.userInterface {
    color: #FFFFFF;
}

.userInterface-header  {
    text-align: center;
    margin-bottom: 30px;
}

.userInterface-header > h5 {
    font-weight: bold;
}

.grievanceInterface {
    color: #FFFFFF;
}

.grievanceInterface-header  {
    text-align: center;
    margin-bottom: 30px;
}

.grievanceInterface-header > h5 {
    font-weight: bold;
}

.grievance-feature {
    display: flex;
    margin-bottom: 20px;
}

.grievance-checked-img {
    height: 30px;
    margin-right: 10px;
}

.grievance-benefits-header {
    text-align: center;
    color:white;
    background-color:#504b94;
}

.grievance-benefits-header > h4 {
    font-size: 22px;
    font-weight: bold;
    background-color:#504b94;

}

.grievance-benefits-list {
    background-color:#504b94;
    padding: 30px;
    color: #FFFFFF;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.grievance-benefit {
    width: 23%;
    padding: 40px 20px;
    /* margin-top: 20px; */
    margin-bottom: 20px;
    text-align: justify;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    border: 0.8px solid #FFFFFF;
    box-shadow: 0px 0px 20px rgba(171, 67, 87, 0.2);
    border-radius: 5px;
}

.grievance-benefit > p {
    margin-bottom: 0 !important;
    font-weight: bold;
} 

@media only screen and (max-width: 540px) {
    .grievanceView {
        padding: 20px;
    }
    .grievanceView-img {
        width: 100%;
        max-height: 500px;
    }
    
    .grievance-features-container {
        padding: 20px;
    }
    
    .grievanceList-image {
        padding: 20px;
    }

    .grievanceInterface-container {
        flex-direction: column;
        padding: 20px;
    }
    
    .grievance-benefits-header > h4 {
        font-size: 18px;
    }

    .grievance-benefit {
        width: 100%;
    }
}

.automation-container {
    padding-top: 100px;
}

.automation-Header {
    padding: 30px;
}

.automation-Header > h2 {
    font-weight: bold;
}

.inventory {
    padding: 30px;
    background-color: #F9FBFF;
}

.inventory-header > a > h3 {
    font-weight: bold;
}

.inventory-header > a:hover {
    color: #007bff !important;
}

.inventory-info {
    display: flex;
    margin-top: 40px;
}

.inventory-text {
    display: flex;
    align-items: center;
}

.inventory-text > p {
    text-align: justify;
}

.inventory-img {
    /* width: 100%; */
    max-height: 450px;
}

.ILMS {
    padding: 30px;
}

.ILMS-header > a > h3 {
    font-weight: bold;
}

.ILMS-header > a:hover {
    color: #007bff !important;
}

.ILMS-info {
    display: flex;
    margin-top: 40px;
}

.ILMS-text {
    display: flex;
    align-items: center;
}

.ILMS-text > p {
    text-align: justify;
}

.ILMS-img {
    width: 100%;
}

.employee-management {
    padding: 30px;
}

.employeeManagement-header > a > h3 {
    font-weight: bold;
}

.employeeManagement-header > a:hover {
    color: #007bff !important;
}

.employeeManagement-info {
    display: flex;
    margin-top: 40px;
}

.employeeManagement-text {
    display: flex;
    align-items: center;
}

.employeeManagement-text > p {
    text-align: justify;
}

.employeeManagement-img {
    width: 100%;
}

.admission {
    padding: 30px;
    background-color: #F9FBFF;
}

.admission-header > a > h3 {
    font-weight: bold;
}

.admission-header > a:hover {
    color: #007bff !important;
}

.admission-info {
    display: flex;
    margin-top: 40px;
}

.admission-text {
    display: flex;
    align-items: center;
}

.admission-text > p {
    text-align: justify;
}

.admission-img {
    width: 100%;
}

.faculty-profile {
    padding: 30px;
    background-color: #F9FBFF;
}

.facultyProfile-header > a > h3 {
    font-weight: bold;
}

.facultyProfile-header > a:hover {
    color: #007bff !important;
}

.facultyProfile-info {
    display: flex;
    margin-top: 40px;
}

.facultyProfile-text {
    display: flex;
    align-items: center;
}

.facultyProfile-text > p {
    text-align: justify;
}

.facultyProfile-img {
    width: 100%;
}

.onlineData-feedback {
    padding: 30px;
    background-color: #F9FBFF;
}

.onlineFeedback-header > a > h3 {
    font-weight: bold;
}

.onlineFeedback-header > a:hover {
    color: #007bff !important;
}

.onlineFeedback-info {
    display: flex;
    margin-top: 40px;
}

.onlineFeedback-text {
    display: flex;
    align-items: center;
}

.onlineFeedback-text > p {
    text-align: justify;
}

.onlineFeedback-img {
    width: 100%;
}

.grievance {
    padding: 30px;
}

.grievance-header > a > h3 {
    font-weight: bold;
}

.grievance-header > a:hover {
    color: #007bff !important;
}

.grievance-info {
    display: flex;
    margin-top: 40px;
}

.grievance-text {
    display: flex;
    align-items: center;
}

.grievance-text > p {
    text-align: justify;
}

.grievance-img {
    width: 100%;
}

.forms {
    padding: 30px;
    background-color: #F9FBFF;
}

.forms-header > a > h3 {
    font-weight: bold;
}

.forms-header > a:hover {
    color: #007bff !important;
}

.forms-info {
    display: flex;
    margin-top: 40px;
}

.forms-text {
    display: flex;
    align-items: center;
}

.forms-text > p {
    text-align: justify;
}

.forms-img {
    width: 100%;
    height: 500px;
}

.leave-management {
    padding: 30px;
}

.leaveManagement-header > a > h3 {
    font-weight: bold;
}

.leaveManagement-header > a:hover {
    color: #007bff !important;
}

.leaveManagement-info {
    display: flex;
    margin-top: 40px;
}

.leaveManagement-text {
    display: flex;
    align-items: center;
}

.leaveManagement-text > p {
    text-align: justify;
}

.leaveManagement-img {
    width: 100%;
}

.accounting {
    padding: 30px;
    background-color: #F9FBFF;
}

.accounting-header > a > h3 {
    font-weight: bold;
}

.accounting-header > a:hover {
    color: #007bff !important;
}

.accounting-info {
    display: flex;
    margin-top: 40px;
}

.accounting-text {
    display: flex;
    align-items: center;
}

.accounting-text > p {
    text-align: justify;
}

.accounting-img {
    width: 100%;
}

.fees {
    padding: 30px;
}

.fees-header > a > h3 {
    font-weight: bold;
}

.fees-header > a:hover {
    color: #007bff !important;
}

.fees-info {
    display: flex;
    margin-top: 40px;
}

.fees-text {
    display: flex;
    align-items: center;
}

.fees-text > p {
    text-align: justify;
}

.fees-img {
    /* width: 100%; */
    max-height: 450px;
}

.payroll {
    padding: 30px;
    background-color: #F9FBFF;
}

.payroll-header > a > h3 {
    font-weight: bold;
}

.payroll-header > a:hover {
    color: #007bff !important;
}

.payroll-info {
    display: flex;
    margin-top: 40px;
}

.payroll-text {
    display: flex;
    align-items: center;
}

.payroll-text > p {
    text-align: justify;
}

.payroll-img {
    width: 100%;
}

.inward {
    padding: 30px;
}

.inward-header > a > h3 {
    font-weight: bold;
}

.inward-header > a:hover {
    color: #007bff !important;
}

.inward-info {
    display: flex;
    margin-top: 40px;
}

.inward-text {
    display: flex;
    align-items: center;
}

.inward-text > p {
    text-align: justify;
}

.inward-img {
    width: 100%;
}

.hostel {
    padding: 30px;
}

.hostel-header > a > h3 {
    font-weight: bold;
}

.hostel-header > a:hover {
    color: #007bff !important;
}

.hostel-info {
    display: flex;
    margin-top: 40px;
}

.hostel-text {
    display: flex;
    align-items: center;
}

.hostel-text > p {
    text-align: justify;
}

.hostel-img {
    width: 100%;
}

.noticeboard {
    padding: 30px;
    background-color: #F9FBFF;
}

.noticeboard-header > a > h3 {
    font-weight: bold;
}

.noticeboard-header > a:hover {
    color: #007bff !important;
}

.noticeboard-info {
    display: flex;
    margin-top: 40px;
}

.noticeboard-text {
    display: flex;
    align-items: center;
}

.noticeboard-text > p {
    text-align: justify;
}

.noticeboard-img {
    width: 100%;
}

.transport {
    padding: 30px;
}

.transport-header > a > h3 {
    font-weight: bold;
}

.transport-header > a:hover {
    color: #007bff !important;
}

.transport-info {
    display: flex;
    margin-top: 40px;
}

.transport-text {
    display: flex;
    align-items: center;
}

.transport-text > p {
    text-align: justify;
}

.transport-img {
    width: 100%;
}

.smsPanel {
    padding: 30px;
    background-color: #F9FBFF;
}

.smsPanel-header > a > h3 {
    font-weight: bold;
}

.smsPanel-header > a:hover {
    color: #007bff !important;
}

.smsPanel-info {
    display: flex;
    margin-top: 40px;
}

.smsPanel-text {
    display: flex;
    align-items: center;
}

.smsPanel-text > p {
    text-align: justify;
}

.smsPanel-img {
    width: 100%;
}

@media only screen and (max-width: 540px) {
    .automation-container {
        padding-top: 80px;
    }

    .automation-Header > h2 {
        font-size: 26px;
        text-align: center;
    }

    .inventory {
        padding: 20px;
    }

    .inventory-header > a > h3 {
        font-size: 24px;
    }

    .inventory-info {
        flex-direction: column-reverse;
    }

    .inventory-text {
        padding: 0;
    }

    .inventory-image {
        padding: 0;
    }

    .ILMS {
        padding: 20px;
    }

    .ILMS-header > a > h3 {
        font-size: 24px;
    }

    .ILMS-info {
        flex-direction: column;
    }

    .ILMS-image {
        padding: 0;
    }

    .ILMS-text {
        padding: 0;
    }

    .employee-management {
        padding: 20px;
    }
    
    .employeeManagement-header > a > h3 {
        font-size: 24px;
    }

    .employeeManagement-info {
        flex-direction: column;
    }

    .employeeManagement-text {
        padding: 0;
    }

    .employeeManagement-image {
        padding: 0;
    }

    .admission {
        padding: 20px;
    }
    
    .admission-header > a > h3 {
        font-size: 24px;
    }

    .admission-info {
        flex-direction: column-reverse;
    }

    .admission-text {
        padding: 0;
    }

    .admission-image {
        padding: 0;
    }

    .faculty-profile {
        padding: 20px;
    }
    
    .facultyProfile-header > a > h3 {
        font-size: 24px;
    }

    .facultyProfile-info {
        flex-direction: column-reverse;
    }

    .facultyProfile-text {
        padding: 0;
    }

    .facultyProfile-image {
        padding: 0;
    }

    .onlineData-feedback {
        padding: 20px;
    }
    
    .onlineFeedback-header > a > h3 {
        font-size: 24px;
    }

    .onlineFeedback-info {
        flex-direction: column-reverse;
    }

    .onlineFeedback-text {
        padding: 0;
    }

    .onlineFeedback-image {
        padding: 0;
    }

    .grievance {
        padding: 20px;
    }
    
    .grievance-header > a > h3 {
        font-size: 24px;
    }

    .grievance-info {
        flex-direction: column;
    }

    .grievance-text {
        padding: 0;
    }

    .grievance-image {
        padding: 0;
    }

    .forms {
        padding: 20px;
    }
    
    .forms-header > a > h3 {
        font-size: 24px;
    }

    .forms-info {
        flex-direction: column-reverse;
    }

    .forms-text {
        padding: 0;
    }

    .forms-image {
        padding: 0;
    }

    .forms-img {
        height: 200px;
    }

    .accounting {
        padding: 20px;
    }
    
    .accounting-header > a > h3 {
        font-size: 24px;
    }

    .accounting-info {
        flex-direction: column-reverse;
    }

    .accounting-text {
        padding: 0;
    }

    .accounting-image {
        padding: 0;
    }

    .leave-management {
        padding: 20px;
    }
    
    .leaveManagement-header > a > h3 {
        font-size: 24px;
    }

    .leaveManagement-info {
        flex-direction: column;
    }

    .leaveManagement-text {
        padding: 0;
    }

    .leaveManagement-image {
        padding: 0;
    }

    .fees {
        padding: 20px;
    }
    
    .fees-header > a > h3 {
        font-size: 24px;
    }

    .fees-info {
        flex-direction: column;
    }

    .fees-text {
        padding: 0;
    }

    .fees-image {
        padding: 0;
    }

    .payroll {
        padding: 20px;
    }
    
    .payroll-header > a > h3 {
        font-size: 24px;
    }

    .payroll-info {
        flex-direction: column-reverse;
    }

    .payroll-text {
        padding: 0;
    }

    .payroll-image {
        padding: 0;
    }

    .inward {
        padding: 20px;
    }
    
    .inward-header > a > h3 {
        font-size: 24px;
    }

    .inward-info {
        flex-direction: column;
    }

    .inward-text {
        padding: 0;
    }

    .inward-image {
        padding: 0;
    }

    .hostel {
        padding: 20px;
    }
    
    .hostel-header > a > h3 {
        font-size: 24px;
    }

    .hostel-info {
        flex-direction: column;
    }

    .hostel-text {
        padding: 0;
    }

    .hostel-image {
        padding: 0;
    }

    .noticeboard {
        padding: 20px;
    }
    
    .noticeboard-header > a > h3 {
        font-size: 24px;
    }

    .noticeboard-info {
        flex-direction: column-reverse;
    }

    .noticeboard-text {
        padding: 0;
    }

    .noticeboard-image {
        padding: 0;
    }

    .transport {
        padding: 20px;
    }
    
    .transport-header > a > h3 {
        font-size: 24px;
    }

    .transport-info {
        flex-direction: column;
    }

    .transport-text {
        padding: 0;
    }

    .transport-image {
        padding: 0;
    }

    .smsPanel {
        padding: 20px;
    }
    
    .smsPanel-header > a > h3 {
        font-size: 24px;
    }

    .smsPanel-info {
        flex-direction: column-reverse;
    }

    .smsPanel-text {
        padding: 0;
    }

    .smsPanel-image {
        padding: 0;
    }
}
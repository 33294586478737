
.formView {
    padding: 100px;
}

.formView-img {
    /* width: 100%; */
    max-height: 500px;
}

.form-list {
    display: flex;
    margin-top: 70px;
}

.form-list-text > h5 {
    font-weight: bold;
}

.formList-img {
    /* width: 100%; */
    max-height: 400px;
}

.customize-form {
    display: flex;
    margin-top: 70px;
}

.customize-form-text > h5 {
    font-weight: bold;
}

.customizeForm-img {
    /* width: 100%; */
    max-height: 300px;
}

.form-response {
    display: flex;
    margin-top: 70px;
}

.form-response-text > h5 {
    font-weight: bold;
}

.formResponse-img {
    /* width: 100%; */
    height: 300px;
}

.formGeneration {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.formGeneration > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.formGeneration > p {
    text-align: justify;
}

.formGeneration-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.exportForm {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.exportForm > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.exportForm > p {
    text-align: justify;
}

.exportForm-img {
    width: 40px;
    margin-bottom: 10px;
}

.stakeholderInfo {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.stakeholderInfo > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.stakeholderInfo > p {
    text-align: justify;
}

.stakeholderInfo-img {
    width: 40px;
    margin-bottom: 10px;
}

.recordMonitor {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.recordMonitor-text > h5 {
    font-weight: bold;
}

.recordMonitor-img {
    height: 40px;
    margin-right: 10px;
}

.pastEnquiry {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.pastEnquiry-text > h5 {
    font-weight: bold;
}

.pastEnquiry-img {
    height: 40px;
    margin-right: 10px;
}

.fastDecision {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.fastDecision-text > h5 {
    font-weight: bold;
}

.fastDecision-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {
    .formView {
        padding: 20px;
    }
    .formView-img {
        width: 100%;
        max-height: 500px;
    }
    .formResponse-img {
        width: 100%;
        height: 300px;
    }
    
    .customizeForm-img {
        width: 100%;
        max-height: 300;
    }

    .formList-img {
        width: 100%;
        max-height: 500px;
    }
    
    .form-list {
        flex-direction: column;
    }
    
    .form-list-text {
        padding: 0;
    }
    
    .formList-image {
        padding: 0;
    }

    .customize-form {
        flex-direction: column-reverse;
    }
    
    .customize-form-text {
        padding: 0;
    }
    
    .customizeForm-image {
        padding: 0;
    }

    .form-response {
        flex-direction: column;
    }
    
    .form-response-text {
        padding: 0;
    }

    .formResponse-img {
        height: auto;
    }
    
    .formResponse-image {
        padding: 0;
    }

    .formGeneration {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .exportForm {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .stakeholderInfo {
        padding: 10px;
        width: 100%;
        border-right: none;
    }

}
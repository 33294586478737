
.main-info {
    background-color: #EEF0FF;
    padding-top: 90px;
}

.main-header {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 10px;
    padding-left: 200px;
    padding-right: 200px;
}

.main-header > h1 {
    font-weight: bold;
}

.main-text {
    padding-top: 10px;
    padding-bottom: 50px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: justify;
}

.main-text > h4 {
    font-size: 20px !important;
}

.page-menus {
    background-color: #F9F9F9;
    padding: 20px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.features {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
}

.menu-img {
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
}

.features-menu-text {
    color: #2457D7;
    font-size: 20px;
    font-weight: bold;
}

.benefits {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    width: 200px;
    cursor: pointer;
}

.benefits-menu-text {
    color: #AB4357;
    font-size: 20px;
    font-weight: bold;
}

.implementation {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    width: 200px;
    cursor: pointer;
}

.implementation-menu-text {
    color: #04AAAB;
    font-size: 20px;
    font-weight: bold;
}

.report {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    width: 200px;
    cursor: pointer;
}

.report-menu-text {
    color: #5FBF13;
    font-size: 20px;
    font-weight: bold;
}

.support {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    width: 200px;
    cursor: pointer;
}

.support-menu-text {
    color: #F48323;
    font-size: 20px;
    font-weight: bold;
}

.ILMS-libraryCard {
    display: flex;
}

.ILMS-img {
    max-height: 500px;}

.libraryCard-img {
    /* width: 100%; */
    max-height: 500px;
}

.features-container {
    background-color: #F2F8FF;
    display: flex;
    padding: 20px;
}

.features-header > h3 {
    font-weight: bold;
    color: #2457D7;
    margin-bottom: 20px;
}

.feature {
    display: flex;
    margin-bottom: 10px;
}

.checked-img {
    height: 20px;
    margin-right: 10px;
}

.feature-text > p {
    text-align: justify;
}

.feature-text-primary {
    font-weight: bold;
    margin-bottom: 5px;
}

.feature-img {
    width: 100%;
}

.library-resources {
    display: flex;
    margin-top: 40px;
    padding: 0px 30px;
}

.library-resource-text > h5 {
    font-weight: bold;
}

.feature-description > p {
    text-align: justify;
}

.libraryResource-img {
    width: 100%;
}

.resource-checked-img {
    height: 20px;
    margin-right: 10px;
}

.quick-glance {
    margin-top: 40px;
    padding: 30px;
    background-color: #184FA0;
}

.quickGlance-header > h3 {
    color: #FFFFFF;
    font-weight: bold;
}

.quickGlance-image {
    padding: 10px 70px;
}

.quickGlance-img {
    /* width: 100%; */
    max-height: 500px;
}

.resource-tagging {
    padding: 30px;
    margin-top: 30px;
    display: flex;
}

.resourceTag-header {
    display: flex;
}

.resourceTag-header > h3 {
    font-weight: bold;
}

.resourceTag-img {
    height: 40px;
    margin-right: 10px;
}

.resource-tag-text {
    padding: 0px 50px;
    margin-top: 20px;
}

.resource-tagging-img {
    width: 100%;
}

.quick-search {
    margin-top: 30px;
    padding: 20px 40px;
    background-color: #F2F8FF;
}

.quickSearch-header > h3 {
    font-weight: bold;
}

.quick-search-text {
    margin-top: 30px;
    display: flex;
}

.quickSearch-container {
    border: 15px solid #0465AC;
    padding: 10px;
}

.quickSearch-img {
    height: 30px;
}

.arrow-img {
    width: 30px;
    margin-right: 10px;
}

.quickSearch-list-header {
    font-size: 20px;
}

.quickSearch-list-item {
    font-size: 18px;
}

.fine-checked-img {
    height: 20px;
    margin-right: 10px;
}

.fine-management-text > h5 {
    font-weight: bold;
}

.fine-management {
    display: flex;
    margin-top: 40px;
    padding: 0px 30px;
}

.fineManagement-img {
    width: 100%;
}

.benefits-container {
    margin-top: 10px; 
    padding-left: 30px;
    display: flex;
}

.benefits-header {
    margin-bottom: 20px;
}

.benefits-header > h3 {
    font-weight: bold;
    color: #AB4357;
}

.benefits-text {
    margin-top: 20px;
}

.benefitLink-img {
    width: 100%;
}

.quickBenefits {
    /* margin-top: 40px; */
    padding: 20px 30px;
    background-color: #B83E5B;
}

.quickBenefits-header {
    display: flex;
    align-items: center;
}

.quickBenefits-header > h3{
    font-weight: bold;
    color: #FFFFFF;
}

.quickBenefits-img {
    height: 40px;
    margin-right: 20px;
}

.quickBenefits-list {
    display: flex;
}

.stateArt {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.stateArt > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.stateArt > p {
    text-align: justify;
}

.stateArt-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.personalized {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.personalized > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.personalized > p {
    text-align: justify;
}

.personalized-img {
    width: 40px;
    margin-bottom: 10px;
}

.documentation {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.documentation > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.documentation > p {
    text-align: justify;
}

.documentation-img {
    width: 40px;
    margin-bottom: 10px;
}

.longTerm-benefits {
    padding: 20px 30px;
    background-color: #B83E5B;
}

.longTerm-benefits-header {
    display: flex;
    align-items: center;
}

.longTerm-benefits-header > h3{
    font-weight: bold;
    color: #FFFFFF;
}

.longTerm-benefits-list {
    margin-bottom: 60px;
}

.longTerm-benefits-img {
    height: 40px;
    margin-right: 20px;
}

.value-efficient {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.valueEfficient-text > h5 {
    font-weight: bold;
}

.valueEfficient-img {
    height: 40px;
    margin-right: 10px;
}

.secure {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.secure-text > h5 {
    font-weight: bold;
}

.secure-img {
    height: 35px;
    margin-right: 15px;
}

.implementation-container {
    margin-top: 30px;
    padding: 20px 30px;
}

.implementation-header > h3 {
    font-weight: bold;
    color: #04AAAB;
}

.implementation-checked-img {
    height: 20px;
    margin-right: 10px;
}

.implementation-data {
    display: flex;
}

.implementation-text {
    margin-top: 15px;
    margin-bottom: 15px;
}

.implementation-text > p{
    text-align: justify;
}

.implementationFlow-img {
    width: 70%;
}

.report-container {
    margin-top: 30px;
    padding: 20px 30px;
    background-color: #F1FFF9;
}

.report-header > h3 {
    font-weight: bold;
    color: #006723;
}

.report-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.reportName {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.reportName > p {
    text-transform: capitalize;
}

.reportLine-img {
    height: 25px;
    width: 2px;
    margin-right: 10px;
}

.library-testimonials-container {
    background-color: #F7F7F7;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px;
    margin-top: 30px;
}

.library-testimonial-1 {
    background-color: #FFFFFF;
    padding: 20px;
    width: 48%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.library-testimonial-2 {
    background-color: #FFFFFF;
    padding: 20px;
    width: 48%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.support-container {
    padding: 20px 30px;
    background-color: #FFF9F2;
    display: flex;
}

.support-header > h3 {
    font-weight: bold;
    color: #F48323;
}

.support-description {
    margin-top: 30px;
}

.support-description > p {
    text-align: justify;
}

.support-note {
    margin-top: 60px;
    font-weight: bold;
}

.support-img {
    width: 30%;
    /* height: 500px; */
}

@media only screen and (max-width: 540px) {

    .main-info {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 130px;
    }

    .main-text {
        padding-left: 0px;
        padding-right: 0px;
    }

    .main-text > h4 {
        font-size: 16px !important;
    }

    .main-header {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
    }

    .main-header > h1 {
        font-size: 26px;
    }

    .page-menus {
        padding: 20px;
        flex-direction: column;
    }

    .features {
        margin: 20px 0px;
    }

    .features-header > h3 {
        font-size: 24px;
    }

    .features-list {
        padding: 0;
    }

    .features-image {
        padding: 0;
    }

    .library-resources {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .libraryResource-image {
        padding: 0;
    }

    .library-resource-text {
        padding: 0;
    }

    .benefits {
        margin: 20px 0px;
    }

    .implementation {
        margin: 20px 0px;
    }

    .report {
        margin: 20px 0px;
    }

    .support {
        margin: 20px 0px;
    }

    .ILMS-libraryCard {
        flex-direction: column;
    }

    .libraryCard-img {
        max-height: 500px;
    }

    .features-container {
        flex-direction: column;
    }

    .quickGlance-header > h3 {
        font-size: 24px;
    }

    .quickGlance-image {
        padding: 0;
    }

    .quickGlance-img {
        height: 200px;
    }

    .resource-tagging {
        flex-direction: column;
        padding: 20px;
    }

    .resourceTag-img {
        height: 30px;
    }

    .resourceTag-header > h3 {
        font-size: 22px;
    }

    .resource-tag {
        padding: 0;
    }

    .resource-tag-text {
        padding: 0;
    }

    .resource-tag-image {
        padding: 0 !important;
    }

    .quick-search {
        padding: 20px;
    }

    .quick-search-text {
        flex-direction: column;
    }

    .quickSearch-image {
        padding: 0;
    }

    .quickSearch-list {
        margin-top: 30px;
        padding: 0;
    }

    .fine-management {
        flex-direction: column;
        padding: 20px;
    }

    .fineManagement-image {
        padding: 0;
    }

    .fine-management-text {
        padding: 0;
    }

    .benefits-container {
        flex-direction: column;
        padding: 20px;
    }

    .benefits-header {
        padding: 0;
    }

    .benefits-image {
        padding: 0;
    }

    .quickBenefits-header > h3 {
        font-size: 22px;
    }
    
    .quickBenefits-header {
        align-items: center;
    }

    .quickBenefits-list {
        flex-direction: column;
    }

    .stateArt {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
    }

    .personalized {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
    }

    .documentation {
        padding: 10px;
        width: 100%;
        border-right: none;
    }

    .longTerm-benefits-header {
        align-items: center;
    }

    .longTerm-benefits-header > h3 {
        font-size: 24px;
    }

    .implementation-container {
        padding: 20px;
        padding: 20px 30px;

    }

    .implementation-header > h3 {
        font-size: 24px;
    }

    .implementation-data {
        flex-direction: column;
    }

    .implementation-list {
        padding: 0;
    }

    .implementationFlow-image {
        padding: 0;
    }

    .report-header > h3 {
        font-size: 24px;
    }

    .library-testimonials-container {
        padding: 20px;
        margin-top: 0;
    }

    .library-testimonial-1 {
        width: 100%;
    }

    .library-testimonial-2 {
        width: 100%;
    }

    .report-container {
        padding: 20px;
    }

    .support-container {
        flex-direction: column;
        padding: 20px;
    }

    .support-data {
        padding: 0;
    }

    .support-header > h3 {
        font-size: 24px;
    }

    .support-note {
        margin-top: 30px;
    }

    .support-image {
        padding: 0;
    }

    .support-img {
        margin-top: 30px;
        /* height: 300px; */
    }
}


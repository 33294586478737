
.academic-container {
    padding-top: 100px;
}

.academic-Header {
    padding: 30px;
}

.academic-Header > h2 {
    font-weight: bold;
}

.student-profile {
    padding: 30px;
    background-color: #F9FFF9;
}

.studentProfile-header > a > h3 {
    font-weight: bold;
}

.studentProfile-header > a:hover {
    color: #007bff !important;
}

.studentProfile-info {
    display: flex;
    margin-top: 40px;
}

.studentProfile-text {
    display: flex;
    align-items: center;
}

.studentProfile-text > p {
    text-align: justify;
}

.studentProfile-img {
    width: 100%;
}

.LMS {
    padding: 30px;
}

.LMS-header > a > h3 {
    font-weight: bold;
}

.LMS-header > a:hover {
    color: #007bff !important;
}

.LMS-info {
    display: flex;
    margin-top: 40px;
}

.LMS-text {
    display: flex;
    align-items: center;
}

.LMS-text > p {
    text-align: justify;
}

.LMS-img {
    width: 100%;
}

.mentoring {
    padding: 30px;
}

.mentoring-header > a > h3 {
    font-weight: bold;
}

.mentoring-header > a:hover {
    color: #007bff !important;
}

.mentoring-info {
    display: flex;
    margin-top: 40px;
}

.mentoring-text {
    display: flex;
    align-items: center;
}

.mentoring-text > p {
    text-align: justify;
}

.mentoring-img {
    width: 100%;
}

.training-placement {
    padding: 30px;
    background-color: #F9FFF9;
}

.trainingPlacement-header > a > h3 {
    font-weight: bold;
}

.trainingPlacement-header > a:hover {
    color: #007bff !important;
}

.training-info {
    display: flex;
    margin-top: 40px;
}

.training-data {
    display: flex;
    align-items: center;
}

.training-data > p {
    text-align: justify;
}

.training-img {
    width: 100%;
}

.autonomy {
    padding: 30px;
    background-color: #F9FFF9;
}

.autonomy-header > a > h3 {
    font-weight: bold;
}

.autonomy-header > a:hover {
    color: #007bff !important;
}

.autonomy-info {
    display: flex;
    margin-top: 40px;
}

.autonomy-text {
    display: flex;
    align-items: center;
}

.autonomy-text > p {
    text-align: justify;
}

.autonomy-img {
    width: 100%;
}

.event-management {
    padding: 30px;
    background-color: #F9FFF9;
}

.eventManagement-header > a > h3 {
    font-weight: bold;
}

.eventManagement-header > a:hover {
    color: #007bff !important;
}

.event-info {
    display: flex;
    margin-top: 40px;
}

.event-text {
    display: flex;
    align-items: center;
}

.event-text > p {
    text-align: justify;
}

.event-img {
    width: 100%;
}

.notice {
    padding: 30px;
}

.notice-header > a > h3 {
    font-weight: bold;
}

.notice-header > a:hover {
    color: #007bff !important;
}

.notice-info {
    display: flex;
    margin-top: 40px;
}

.notice-text {
    display: flex;
    align-items: center;
}

.notice-text > p {
    text-align: justify;
}

.notice-img {
    width: 100%;
}

.meeting {
    padding: 30px;
}

.meeting-header > a > h3 {
    font-weight: bold;
}

.meeting-header > a:hover {
    color: #007bff !important;
}

.meeting-info {
    display: flex;
    margin-top: 40px;
}

.meeting-text {
    display: flex;
    align-items: center;
}

.meeting-text > p {
    text-align: justify;
}

.meeting-img {
    width: 100%;
}

.assignment-management {
    padding: 30px;
}

.assignmentManagement-header > a > h3 {
    font-weight: bold;
}

.assignmentManagement-header > a:hover {
    color: #007bff !important;
}

.assignmentManagement-info {
    display: flex;
    margin-top: 40px;
}

.assignmentManagement-text {
    display: flex;
    align-items: center;
}

.assignmentManagement-text > p {
    text-align: justify;
}

.assignmentManagement-img {
    width: 100%;
}

.academic {
    padding: 30px;
    background-color: #F9FFF9;
}

.academicData-header > a > h3 {
    font-weight: bold;
}

.academicData-header > a:hover {
    color: #007bff !important;
}

.academic-info {
    display: flex;
    margin-top: 40px;
}

.academic-text {
    display: flex;
    align-items: center;
}

.academic-text > p {
    text-align: justify;
}

.academic-img {
    width: 100%;
}

.analysis {
    padding: 30px;
    background-color: #F9FFF9;
}

.analysis-header > a > h3 {
    font-weight: bold;
}

.analysis-header > a:hover {
    color: #007bff !important;
}

.analysis-info {
    display: flex;
    margin-top: 40px;
}

.analysis-text {
    display: flex;
    align-items: center;
}

.analysis-text > p {
    text-align: justify;
}

.analysis-img {
    width: 100%;
}

@media only screen and (max-width: 540px) {
    .academic-container {
        padding-top: 80px;
    }

    .academic-Header > h2 {
        font-size: 26px;
        text-align: center;
    }

    .student-profile {
        padding: 20px;
    }

    .studentProfile-header > a > h3 {
        font-size: 24px;
    }

    .studentProfile-info {
        flex-direction: column-reverse;
    }

    .studentProfile-text {
        padding: 0;
    }

    .studentProfile-image {
        padding: 0;
    }

    .LMS {
        padding: 20px;
    }

    .LMS-header > a > h3 {
        font-size: 24px;
    }

    .LMS-info {
        flex-direction: column;
    }

    .LMS-image {
        padding: 0;
    }

    .LMS-text {
        padding: 0;
    }

    .mentoring {
        padding: 20px;
    }
    
    .mentoring-header > a > h3 {
        font-size: 24px;
    }

    .mentoring-info {
        flex-direction: column;
    }

    .mentoring-text {
        padding: 0;
    }

    .mentoring-image {
        padding: 0;
    }

    .training-placement {
        padding: 20px;
    }
    
    .trainingPlacement-header > a > h3 {
        font-size: 24px;
    }

    .training-info {
        flex-direction: column-reverse;
    }

    .training-data {
        padding: 0;
    }

    .training-image {
        padding: 0;
    }

    .autonomy {
        padding: 20px;
    }
    
    .autonomy-header > a > h3 {
        font-size: 24px;
    }

    .autonomy-info {
        flex-direction: column-reverse;
    }

    .autonomy-text {
        padding: 0;
    }

    .autonomy-image {
        padding: 0;
    }

    .event-management {
        padding: 20px;
    }
    
    .eventManagement-header > a > h3 {
        font-size: 24px;
    }

    .event-info {
        flex-direction: column-reverse;
    }

    .event-text {
        padding: 0;
    }

    .event-image {
        padding: 0;
    }

    .notice {
        padding: 20px;
    }
    
    .notice-header > a > h3 {
        font-size: 24px;
    }

    .notice-info {
        flex-direction: column;
    }

    .notice-text {
        padding: 0;
    }

    .notice-image {
        padding: 0;
    }

    .meeting {
        padding: 20px;
    }
    
    .meeting-header > a > h3 {
        font-size: 24px;
    }

    .meeting-info {
        flex-direction: column;
    }

    .meeting-text {
        padding: 0;
    }

    .meeting-image {
        padding: 0;
    }

    .academic {
        padding: 20px;
    }
    
    .academicData-header > a > h3 {
        font-size: 24px;
    }

    .academic-info {
        flex-direction: column-reverse;
    }

    .academic-text {
        padding: 0;
    }

    .academic-image {
        padding: 0;
    }

    .assignment-management {
        padding: 20px;
    }
    
    .assignmentManagement-header > a > h3 {
        font-size: 24px;
    }

    .assignmentManagement-info {
        flex-direction: column;
    }

    .assignmentManagement-text {
        padding: 0;
    }

    .assignmentManagement-image {
        padding: 0;
    }

    .analysis {
        padding: 20px;
    }
    
    .analysis-header > a > h3 {
        font-size: 24px;
    }

    .analysis-info {
        flex-direction: column-reverse;
    }

    .analysis-text {
        padding: 0;
    }

    .analysis-image {
        padding: 0;
    }
}
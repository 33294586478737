
.sitemap-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.homeLink {
    padding: 20px 30px;
}

.homeLink > a {
    color: #007bff !important;
    text-decoration: underline;
}

.outcomeMenu {
    margin-bottom: 30px;
}

.outcomeMenu > a {
    color: #007bff !important;
    text-decoration: underline;
}

.product-menu {
    padding: 20px 30px;
}

.product-menu > h5 {
    font-weight: bold;
    margin-bottom: 20px;
}

.inventoryMenu > a {
    color: #007bff !important;
    text-decoration: underline;
}

.academicMenu > a {
    color: #007bff !important;
    text-decoration: underline;
}

.adminMenu > a {
    color: #007bff !important;
    text-decoration: underline;
}

.accountingMenu > a {
    color: #007bff !important;
    text-decoration: underline;
}

.success-menu {
    padding: 20px 30px;
}

.success-menu > h5 {
    font-weight: bold;
    margin-bottom: 20px;
}

.success-links {
    display: flex;
}

.success-links > div > a{
    color: #007bff !important;
    text-decoration: underline;
}

.resource-menu {
    padding: 20px 30px;
}

.resource-menu > h5 {
    font-weight: bold;
    margin-bottom: 20px;
}

.resource-links {
    display: flex;
}

.resource-links > div > a{
    color: #007bff !important;
    text-decoration: underline;
}

.about-menu {
    padding: 20px 30px;
}

.about-menu > h5 {
    font-weight: bold;
    margin-bottom: 20px;
}

.about-links {
    display: flex;
}

.about-links > div > a{
    color: #007bff !important;
    text-decoration: underline;
}

.loginLink {
    padding: 20px 30px;
}

.loginLink > a {
    color: #007bff !important;
    text-decoration: underline;
}

@media only screen and (max-width: 540px) {
    .sitemap-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .homeLink {
        padding: 20px;
    }

    .product-menu {
        padding: 20px;
    }

    .success-menu {
        padding: 20px;
    }

    .success-links {
        flex-direction: column;
    }

    .success-links > div {
        padding: 0 !important;
    }

    .resource-menu {
        padding: 20px;
    }

    .resource-links {
        flex-direction: column;
    }

    .resource-links > div {
        padding: 0 !important;
    }

    .about-menu {
        padding: 20px;
    }

    .about-links {
        flex-direction: column;
    }

    .about-links > div {
        padding: 0 !important;
    }

    .loginLink {
        padding: 20px;
    }

}
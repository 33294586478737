
.events-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.events-info {
    text-align: center;
    margin-bottom: 20px;
}

.events-info > h1 {
    font-weight: bold;
    background: linear-gradient(to right, #D4C21F, #11D324);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.eventsList-container {
    padding: 20px 30px;
}

.eventList {
    background-color: #FDFDFD;
    padding: 10px;
}

.eventDetails {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #C4C4C4;
}

.eventDetails:last-child {
    border-bottom: none;
}

.eventDate-title {
    display: flex;
}

.eventDate-title > h6 {
    margin-right: 20px;
}

.eventVenue {
    text-align: right;
}

@media only screen and (max-width: 540px) {

    .events-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .events-info > h1 {
        font-size: 22px;
    }

    .eventsList-container {
        padding: 20px;
    }

    .eventList {
        padding: 0;
    }

    .eventDate-title {
        flex-direction: column;
    }
}
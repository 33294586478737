
.iqacView {
    padding: 100px;
}

.iqacView-img {
    width: 100%;
}

.track-activity {
    display: flex;
    margin-top: 30px;
}

.track-activity-text > h5 {
    font-weight: bold;
}

.trackActivity-img {
    /* width: 100%; */
    max-height: 275px;
}

.custom-report {
    padding: 30px;
    display: flex;
}

.custom-report-text > h5 {
    font-weight: bold;
}

.customReport-img {
    /* width: 100%; */
    max-height: 275px;
 
}

.complete-analysis {
    padding: 30px;
    display: flex;
    background-color: #f8f9fe;
}

.complete-analysis-text > h5 {
    font-weight: bold;
}

.completeAnalysis-img {
    width: 100%;
}

.iqac-benefits-container {
    margin-top: 40px;
    padding: 30px;
    display: flex;
    background-color: #FFF6F9;
}

.quickBenefits2 {
    padding: 20px 30px;
    background-color: #FFF6F9;
}

.quickBenefits2-header {
    display: flex;
    align-items: center;
}

.quickBenefits2-header > h3{
    font-weight: bold;
    color: #AB4357;
}

.transparencyProcess {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.transparencyProcess > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.transparencyProcess > p {
    text-align: justify;
}

.transparencyProcess-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.simpleIntegration {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.simpleIntegration > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.simpleIntegration > p {
    text-align: justify;
}

.simpleIntegration-img {
    width: 40px;
    margin-bottom: 10px;
}

.easyOperation {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.easyOperation > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.easyOperation > p {
    text-align: justify;
}

.easyOperation-img {
    width: 40px;
    margin-bottom: 10px;
}

.longTerm-benefits2 {
    padding: 20px 30px;
    background-color: #FFF6F9;
}

.longTerm-benefits2-header {
    display: flex;
    align-items: center;
}

.longTerm-benefits2-header > h3{
    font-weight: bold;
    color: #AB4357;
}

.modernization {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.modernization-text > h5 {
    font-weight: bold;
}

.modernization-img {
    height: 40px;
    margin-right: 10px;
}

.preparation {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.preparation-text > h5 {
    font-weight: bold;
}

.preparation-img {
    height: 35px;
    margin-right: 15px;
}

.deployment {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.deployment-text > h5 {
    font-weight: bold;
}

.deployment-img {
    height: 35px;
    margin-right: 15px;
}

.report-image {
    padding: 20px 100px;
    text-align: center;
}

.report-img {
    /* height: 600px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); */
    max-height: 500px;
}

@media only screen and (max-width: 540px) {
    .iqacView {
        padding: 20px;
    }
    .report-img {
        /* height: 600px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); */
        max-height: 500px;
        width: 265%;
    }
    .trackActivity-img {
        /* height: 600px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); */
        max-height: 275px;
        width: 100%;

    }
    .track-activity {
        flex-direction: column;
    }

    .track-activity-text {
        padding: 0;
    }
    
    .trackActivity-image {
        padding: 0;
    }

    .custom-report {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .custom-report-text {
        padding: 0;
    }

    .customReport-image {
        padding: 0;
    }

    .complete-analysis {
        flex-direction: column;
        padding: 20px;
    }

    .complete-analysis-image {
        padding: 0;
    }

    .completeAnalysis-text {
        padding: 0;
    }
    
    .quickBenefits2 {
        padding: 20px;
    }

    .transparencyProcess {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .simpleIntegration {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .easyOperation {
        padding: 10px;
        width: 100%;
        border-right: none;
    }
    
    .longTerm-benefits2 {
        padding: 20px;
    }

}
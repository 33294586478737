
.courseFileView {
    padding: 100px;
}

.courseFileView-img {
    width: 100%;
}

.course-session {
    display: flex;
    margin-top: 70px;
}

.course-session-text > h5 {
    font-weight: bold;
}

.courseSession-img {
    width: 100%;
}

.course-exam {
    display: flex;
    margin-top: 70px;
}

.course-exam-text > h5 {
    font-weight: bold;
}

.courseExam-img {
    width: 100%;
}

.courseFileFeatures {
    padding: 100px;
}

.courseFileFeatures-img {
    /* width: 100%; */
    max-height: 650px;
}

.quickGenerate {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.quickGenerate > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.quickGenerate > p {
    text-align: justify;
}

.quickGenerate-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.perfectCustomized {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.perfectCustomized > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.perfectCustomized > p {
    text-align: justify;
}

.perfectCustomized-img {
    width: 40px;
    margin-bottom: 10px;
}

.conventionalProcess {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.conventionalProcess > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.conventionalProcess > p {
    text-align: justify;
}

.conventionalProcess-img {
    width: 40px;
    margin-bottom: 10px;
}

.monitorRecords {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.monitorRecords-text > h5 {
    font-weight: bold;
}

.monitorRecords-img {
    height: 40px;
    margin-right: 10px;
}

.onCloud {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.onCloud-text > h5 {
    font-weight: bold;
}

.onCloud-img {
    height: 35px;
    margin-right: 15px;
}

.accreditationIntegration {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.accreditationIntegration-text > h5 {
    font-weight: bold;
}

.accreditationIntegration-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {
    .courseFileView {
        padding: 20px;
    }
    .courseFileFeatures-img {
        width: 100%;
        max-height: 650px;
    }
    .course-session {
        margin-top: 30px;
        flex-direction: column;
    }

    .course-session-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .courseSession-image {
        padding: 0;
    }

    .course-exam {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .course-exam-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .courseExam-image {
        padding: 0;
    }
    
    .courseFileFeatures {
        padding: 20px;
    }

    .quickGenerate {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
    }

    .perfectCustomized {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
    }

    .conventionalProcess {
        padding: 10px;
        width: 100%;
        border-right: none;
    }
}




.alumniView {
    padding: 100px;
}

.alumniView-img {
    width: 100%;
}

.features-container3 {
    /* background-color: #F2F8FF; */
    padding: 30px;
}

.alumniDatabase {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 40px;
    margin-top: 40px; */
}

.alumniDatabase > h4 {
    font-weight: bold;
}

.alumniDatabase-img {
    height: 70px;
    margin-right: 40px;
}

.reunions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 40px; */
}

.reunions > h4 {
    font-weight: bold;
}

.reunions-img {
    height: 70px;
    margin-left: 40px;
}

.features2-container {
    padding: 30px;
}

.studentDevelopment {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 40px; */
}

.studentDevelopment > h4 {
    font-weight: bold;
}

.studentDevelopment-img {
    height: 70px;
    margin-right: 40px;
}

.alumniFunds {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 40px; */
}

.alumniFunds > h4 {
    font-weight: bold;
}

.alumniFunds-img {
    height: 70px;
    margin-left: 40px;
}

.features3-container {
    /* background-color: #F2F8FF; */
    padding: 30px;
}

.employerBranding {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 40px; */
}

.employerBranding > h4 {
    font-weight: bold;
}

.employerBranding-img {
    height: 70px;
    margin-right: 40px;
}

.talentNeeds {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 40px; */
}

.talentNeeds > h4 {
    font-weight: bold;
}

.talentNeeds-img {
    height: 70px;
    margin-left: 40px;
}

.features4-container {
    padding: 30px;
}

.regularUpdate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 40px; */
}

.regularUpdate > h4 {
    font-weight: bold;
}

.regularUpdate-img {
    height: 70px;
    margin-right: 40px;
}

@media only screen and (max-width: 540px) {
    .alumniView {
        padding: 20px;
    }

    .features-container3 {
        padding: 20px;
    }

    .alumniDatabase {
        flex-direction: column;
        justify-content: center;
    }

    .alumniDatabase > img {
        margin-right: 0;
        margin-bottom: 20px;
        height: 120px;
    }

    .alumniDatabase > h4 {
        text-align: center;
        font-size: 20px;
    }

    .reunions {
        flex-direction: column;
        justify-content: center;
    }

    .reunions > img {
        margin-left: 0;
        margin-bottom: 20px;
        height: 120px;
    }

    .reunions > h4 {
        text-align: center;
        font-size: 20px;
    }

    .features2-container {
        padding: 20px;
    }

    .studentDevelopment {
        flex-direction: column;
        justify-content: center;
    }

    .studentDevelopment > img {
        margin-right: 0;
        margin-bottom: 20px;
        height: 120px;
    }

    .studentDevelopment > h4 {
        text-align: center;
        font-size: 20px;
    }

    .alumniFunds {
        flex-direction: column;
        justify-content: center;
    }

    .alumniFunds > img {
        margin-left: 0;
        margin-bottom: 20px;
        height: 120px;
    }

    .alumniFunds > h4 {
        text-align: center;
        font-size: 20px;
    }

    .features3-container {
        padding: 20px;
    }

    .employerBranding {
        flex-direction: column;
        justify-content: center;
    }

    .employerBranding > img {
        margin-right: 0;
        margin-bottom: 20px;
        height: 120px;
    }

    .employerBranding > h4 {
        text-align: center;
        font-size: 20px;
    }

    .talentNeeds {
        flex-direction: column;
        justify-content: center;
    }

    .talentNeeds > img {
        margin-left: 0;
        margin-bottom: 20px;
        height: 120px;
    }

    .talentNeeds > h4 {
        text-align: center;
        font-size: 20px;
    }

    .features4-container {
        padding: 20px;
    }

    .regularUpdate {
        flex-direction: column;
        justify-content: center;
    }

    .regularUpdate > img {
        margin-right: 0;
        margin-bottom: 20px;
        height: 120px;
    }

    .regularUpdate > h4 {
        text-align: center;
        font-size: 20px;
    }
}
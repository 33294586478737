
.transportView {
    padding: 50px;
}

.transportView-img {
    width: 100%;
}

.manage-driver {
    display: flex;
    margin-top: 30px;
}

.manage-driver-text > h5 {
    font-weight: bold;
}

.manageDriver-img {
    width: 100%;
}

.manage-vehicle {
    padding: 30px;
    display: flex;
}

.manage-vehicle-text > h5 {
    font-weight: bold;
}

.manageVehicle-img {
    width: 100%;
}

.manage-route {
    padding: 30px;
    display: flex;
    background-color: #F2F8FF;
}

.manage-route-text > h5 {
    font-weight: bold;
}

.manageRoute-img {
    width: 100%;
}

.overPlanning {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.overPlanning > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.overPlanning > p {
    text-align: justify;
}

.overPlanning-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.adminTime {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.adminTime > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.adminTime > p {
    text-align: justify;
}

.adminTime-img {
    width: 40px;
    margin-bottom: 10px;
}

.saveCost {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.saveCost > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.saveCost > p {
    text-align: justify;
}

.saveCost-img {
    width: 40px;
    margin-bottom: 10px;
}

.digital {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.digital-text > h5 {
    font-weight: bold;
}

.digital-img {
    height: 40px;
    margin-right: 10px;
}

.fuelCost {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.fuelCost-text > h5 {
    font-weight: bold;
}

.fuelCost-img {
    height: 35px;
    margin-right: 15px;
}

.transparentData {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.transparentData-text > h5 {
    font-weight: bold;
}

.transparentData-img {
    height: 35px;
    margin-right: 15px;
}

@media only screen and (max-width: 540px) {
    .transportView {
        padding: 20px;
    }

    .transportView-img {
        height: auto;
    }

    .manage-driver {
        flex-direction: column;
    }

    .manage-driver-text {
        padding: 0;
    }
    
    .manageDriver-image {
        padding: 0;
    }

    .manage-vehicle {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .manage-vehicle-text {
        padding: 0;
    }

    .manageVehicle-image {
        padding: 0;
    }

    .manage-route {
        flex-direction: column;
        padding: 20px;
    }

    .manageRoute-image {
        padding: 0;
    }

    .manage-route-text {
        padding: 0;
    }

    .overPlanning {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .adminTime {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .saveCost {
        padding: 10px;
        width: 100%;
        border-right: none;
    }

}
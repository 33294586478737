
.businessCase-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.businessCase-message {
    padding: 20px 150px;
}

.businessCase-message > h4 {
    text-align: justify;
}

.team-name {
    text-align: right;
    margin-top: 20px;
}

.businessCase-enquiry-container {
    display: flex;
}

.businessCase-enquiry-info {
    background-color: #4A64EC;
    color: #FFFFFF;
}

.enquiryDetails {
    padding-left: 110px;
    padding-right: 110px;
    padding-top: 200px;
    padding-bottom: 80px;
}

.enquiryDetails > h5 {
    text-align: justify;
}

.enquiryMessage {
    text-align: right;
    padding-right: 20px;
    margin-bottom: 70px;
}

.businessCase-enquiry-form {
    padding: 100px;
}

.businessCase-enquiry-form input {
    background-color: transparent;
    color: #818181;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
}

.businessCase-enquiry-form input:focus {
    background-color: transparent;
    color: #818181;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
    box-shadow: none;
}

.businessCase-enquiry-form input::placeholder {
    color: #818181;
    font-size: 14px;
}

.submit-businessCase {
    background-color: #4A64EC;
    border-radius: 10px;
    padding: 6px 12px;
    color: #FFFFFF;
}

.submit-businessCase:hover {
    color: #FFFFFF;
}

@media only screen and (max-width: 540px) {

    .businessCase-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }
    
    .businessCase-message {
        padding: 20px;
    }

    .businessCase-enquiry-container {
        flex-direction: column;
    }

    .enquiryDetails {
        padding: 20px;
    }

    .enquiryMessage {
        padding: 10px;
        margin-bottom: 20px;
    }

    .businessCase-enquiry-form {
        padding: 30px 20px;
    }
}

.accountingView {
    padding: 100px;
}

.accountingView-img {
    width: 100%;
}

.manage-vendor {
    display: flex;
    margin-top: 70px;
}

.manage-vendor-text > h5 {
    font-weight: bold;
}

.manageVendor-img {
    width: 100%;
}

.manage-pr {
    display: flex;
    margin-top: 70px;
}

.manage-pr-text > h5 {
    font-weight: bold;
}

.managePR-img {
    width: 100%;
}

.manage-po {
    display: flex;
    margin-top: 70px;
}

.manage-po-text > h5 {
    font-weight: bold;
}

.managePO-img {
    width: 100%;
}

.manage-expense {
    display: flex;
    margin-top: 70px;
}

.manage-expense-text > h5 {
    font-weight: bold;
}

.manageExpense-img {
    width: 100%;
}

.make-payment {
    display: flex;
    margin-top: 70px;
}

.make-payment-text > h5 {
    font-weight: bold;
}

.makePayment-img {
    width: 100%;
}

.falseReport {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.falseReport > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.falseReport > p {
    text-align: justify;
}

.falseReport-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.dataManipulation {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.dataManipulation > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.dataManipulation > p {
    text-align: justify;
}

.dataManipulation-img {
    width: 40px;
    margin-bottom: 10px;
}

.individualAudit {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.individualAudit > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.individualAudit > p {
    text-align: justify;
}

.individualAudit-img {
    width: 40px;
    margin-bottom: 10px;
}

.depth-analysis {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.depth-analysis-text > h5 {
    font-weight: bold;
}

.depth-analysis-img {
    height: 40px;
    margin-right: 10px;
}

.automateUpdate {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.automateUpdate-text > h5 {
    font-weight: bold;
}

.automateUpdate-img {
    height: 40px;
    margin-right: 10px;
}

.dataLoss {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.dataLoss-text > h5 {
    font-weight: bold;
}

.dataLoss-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {
    .accountingView {
        padding: 20px;
    }

    .manage-vendor {
        margin-top: 30px;
        flex-direction: column;
    }

    .manage-vendor-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .manageVendor-image {
        padding: 0;
    }

    .manage-pr {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .manage-pr-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .managePR-image {
        padding: 0;
    }

    .manage-po {
        margin-top: 30px;
        flex-direction: column;
    }

    .manage-po-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .managePO-image {
        padding: 0;
    }

    .manage-expense {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .manage-expense-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .manageExpense-image {
        padding: 0;
    }

    .make-payment {
        margin-top: 30px;
        flex-direction: column;
    }

    .make-payment-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .makePayment-image {
        padding: 0;
    }
    
    .falseReport {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .dataManipulation {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .individualAudit {
        padding: 10px;
        border-right: none;
        width: 100%;
    }

}

.leaveView {
    padding: 100px;
}

.leaveView-img {
    /* width: 100%; */
    max-height: 500px;
}

.manage-holiday {
    display: flex;
    margin-top: 70px;
}

.manage-holiday-text > h5 {
    font-weight: bold;
}

.manageHoliday-img {
    width: 100%;
}

.leave-policy {
    display: flex;
    margin-top: 70px;
}

.leave-policy-text > h5 {
    font-weight: bold;
}

.leavePolicy-img {
    width: 100%;
}

.attendance-log {
    display: flex;
    margin-top: 70px;
}

.attendance-log-text > h5 {
    font-weight: bold;
}

.attendanceLog-img {
    width: 100%;
}

.leave-approver {
    display: flex;
    margin-top: 70px;
}

.leave-approver-text > h5 {
    font-weight: bold;
}

.leaveApprover-img {
    width: 100%;
}

.workManagement {
    display: flex;
    margin-top: 70px;
}

.workManagement-text > h5 {
    font-weight: bold;
}

.workManagement-img {
    width: 100%;
}

.goPaperless {
    display: flex;
    flex-direction: column;
    padding: 50px;
    text-align: center;
    width: 33%;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.goPaperless > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.goPaperless > p {
    text-align: justify;
}

.goPaperless-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.highCommunication {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.highCommunication > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.highCommunication > p {
    text-align: justify;
}

.highCommunication-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.preventError {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.preventError > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.preventError > p {
    text-align: justify;
}

.preventError-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.realTimeData {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.realTimeData > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.realTimeData > p {
    text-align: justify;
}

.realTimeData-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.leaveBalance {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.leaveBalance > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.leaveBalance > p {
    text-align: justify;
}

.leaveBalance-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.organizationValue {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.organizationValue > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.organizationValue > p {
    text-align: justify;
}

.organizationValue-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 540px) {
    .leaveView {
        padding: 20px;
    }

    .manage-holiday {
        margin-top: 30px;
        flex-direction: column;
    }
    .leaveView-img {
        width: 100%;
        max-height: 500px;
    }
    .manage-holiday-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .manageHoliday-image {
        padding: 0;
    }

    .leave-policy {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .leave-policy-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .leavePolicy-image {
        padding: 0;
    }

    .attendance-log {
        margin-top: 30px;
        flex-direction: column;
    }

    .attendance-log-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .attendanceLog-image {
        padding: 0;
    }

    .leave-approver {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .leave-approver-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .leaveApprover-image {
        padding: 0;
    }

    .workManagement {
        margin-top: 30px;
        flex-direction: column;
    }

    .workManagement-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .workManagement-image {
        padding: 0;
    }

    .purchase-order {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .purchase-order-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .purchaseOrder-image {
        padding: 0;
    }

    .benefits-info {
        flex-direction: column;
    }

    .benefits-header > h3 {
        font-size: 24px;
    }

    .benefits-data {
        margin-top: 30px;
    }

    .goPaperless {
        width: 100%;
        margin-right: 0;
    }

    .highCommunication {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .preventError {
        width: 100%;
        margin-left: 0;
    }

    .realTimeData {
        width: 100%;
        margin-right: 0;
    }

    .leaveBalance {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .organizationValue {
        width: 100%;
        margin-left: 0;
    }
}



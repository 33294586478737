
.certification-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.certification-info {
    text-align: center;
    margin-bottom: 20px;
}

.certification-info > h1 {
    font-weight: bold;
    background: linear-gradient(to right, #FF2E01, #AD00FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ISO-container {
    background: linear-gradient(to right, #C8FFF8, #F7FFFE);
    display: flex;
    padding: 80px;
}

.ISO-img {
    height: 200px;
}

.ISO-text {
    padding: 50px;
}

.trophies {
    margin-bottom: 10px;
}

.ISO-header > h3 {
    font-weight: bold;
}

.SCGJ-container {
    background: linear-gradient(to right, #D0FFC8, #F8FFF7);
    display: flex;
    padding: 80px;
}

.SCGJ-img {
    height: 200px;
}

.SCGJ-text {
    padding: 50px;
}

.SCGJ-header > h3 {
    font-weight: bold;
}

.NASSCOM-container {
    background: linear-gradient(to right, #FFC8C8, #FFF7F7);
    display: flex;
    padding: 80px;
}

.NASSCOM-img {
    height: 112px;
}

.NASSCOM-text {
    padding: 50px;
}

.NASSCOM-header > h3 {
    font-weight: bold;
}

.DIPP-container {
    background: linear-gradient(to right, #C8D1FF, #F7F9FF);
    display: flex;
    padding: 80px;
}

.DIPP-img {
    height: 160px;
}

.DIPP-text {
    padding: 50px;
}

.DIPP-header > h3 {
    font-weight: bold;
}

.RGAP-container {
    background: linear-gradient(to right, #E7C8FF, #FCF7FF);
    display: flex;
    padding: 80px;
}

.RGAP-img {
    height: 180px;
}

.RGAP-text {
    padding: 50px;
}

.RGAP-header > h3 {
    font-weight: bold;
}

@media only screen and (max-width: 540px) {

    .certification-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .certification-info > h1 {
        font-size: 22px;
    }

    .ISO-container {
        padding: 20px;
        flex-direction: column;
        text-align: center;
        background: linear-gradient(to bottom, #C8FFF8, #F7FFFE);
    }

    .SCGJ-container {
        padding: 20px;
        flex-direction: column;
        text-align: center;
        background: linear-gradient(to bottom, #D0FFC8, #F8FFF7);
    }

    .NASSCOM-container {
        padding: 20px;
        flex-direction: column;
        text-align: center;
        background: linear-gradient(to bottom, #FFC8C8, #FFF7F7);
    }

    .DIPP-container {
        padding: 20px;
        flex-direction: column;
        text-align: center;
        background: linear-gradient(to bottom, #C8D1FF, #F7F9FF);
    }

    .RGAP-container {
        padding: 20px;
        flex-direction: column;
        text-align: center;
        background: linear-gradient(to bottom, #E7C8FF, #FCF7FF);
    }
}
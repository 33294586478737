
.learningSupport-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.learningSupport-message {
    text-align: center;
}

.learningSupport-message > h1 {
    font-weight: bold;
}

.learningSupport-container {
    padding: 30px;
    background-color: #232F71;
    color: #FFFFFF;
    margin-top: 40px;
}

.learningSupportDetails {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.knowledge-base {
    width: 33%;
    text-align: center;
    padding: 50px;
    border-right: 1px solid #FFFFFF;
}

.knowledgeBase-img {
    height: 100px;
    margin-bottom: 40px;
}

.train-team {
    width: 33%;
    text-align: center;
    padding: 50px;
    border-right: 1px solid #FFFFFF;
}

.trainTeam-img {
    height: 100px;
    margin-bottom: 40px;
}

.empower-learner {
    width: 33%;
    text-align: center;
    padding: 50px;
}

.empowerLearner-img {
    height: 100px;
    margin-bottom: 40px;
}

.vmedulifeLearning-message {
    padding: 40px 200px;
    margin-top: 40px;
}

.vmedulifeLearning-message > h4 {
    text-align: justify;
}

.vmedulife-name {
    text-align: right;
    margin-top: 50px;
}

.learningSupportContact {
    text-align: center;
    margin-top: 70px;
}

.learningSupportContact > h5 {
    font-weight: bold;
}

.contactSupportButton {
    background: #232F71;
    color: #FFFFFF;
    padding: 6px 12px;
    box-shadow: 0px 0px 10px rgba(35, 47, 113, 0.5);
    border-radius: 10px;
    margin-top: 30px;
}

.contactSupportButton:hover {
    background: #232F71;
    color: #FFFFFF;
}

@media only screen and (max-width: 540px) {

    .learningSupport-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .learningSupport-message {
        padding: 20px;
    }

    .learningSupport-message > h1 {
        font-size: 22px;
    }

    .learningSupportDetails {
        flex-direction: column;
    }

    .knowledge-base {
        width: 100%;
        margin-bottom: 20px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
    }

    .train-team {
        width: 100%;
        margin-bottom: 20px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
    }

    .empower-learner {
        width: 100%;
        margin-bottom: 20px;
        border-right: none;
    }

    .vmedulifeLearning-message {
        padding: 20px;
    }

    .vmedulifeLearning-message > h4 {
        font-size: 24px;
        text-align: justify;
    }

    .vmedulife-name {
        margin-top: 20px;
    }

    .learningSupportContact {
        margin-top: 20px;
        padding: 20px;
    }
}

.examView {
    padding: 100px;
}

.examView-img {
    /* width: 100%; */
    max-height: 500px;
}

.schedule-exam {
    display: flex;
    margin-top: 70px;
}

.schedule-exam-text > h5 {
    font-weight: bold;
}

.scheduleExam-img {
    width: 100%;
}

.question-type {
    display: flex;
    margin-top: 70px;
}

.question-type-text > h5 {
    font-weight: bold;
}

.questionType-img {
    width: 100%;
}

.student-analysis {
    display: flex;
    margin-top: 70px;
}

.student-analysis-text > h5 {
    font-weight: bold;
}

.studentAnalysis-img {
    width: 100%;
}

.exam-analysis {
    display: flex;
    margin-top: 70px;
}

.exam-analysis-text > h5 {
    font-weight: bold;
}

.examAnalysis-img {
    width: 100%;
}

.question-bank {
    display: flex;
    margin-top: 70px;
}

.question-bank-text > h5 {
    font-weight: bold;
}

.questionBank-img {
    width: 100%;
}

.benefits-container3 {
    padding: 30px;
    display: flex;
    background: #FFF6F9;
}

.quickBenefits3 {
    padding: 20px 30px;
    background-color: #B83E5B;
}

.assessment {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.assessment > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.assessment > p {
    text-align: justify;
}

.assessment-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.environment {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.environment > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.environment > p {
    text-align: justify;
}

.environment-img {
    width: 40px;
    margin-bottom: 10px;
}

.evaluation {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.evaluation > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.evaluation > p {
    text-align: justify;
}

.evaluation-img {
    width: 40px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 540px) {
    .examView {
        padding: 20px;
    }

    .schedule-exam {
        margin-top: 30px;
        flex-direction: column;
    }

    .schedule-exam-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .scheduleExam-image {
        padding: 0;
    }

    .question-type {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .question-type-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .questionType-image {
        padding: 0;
    }

    .student-analysis {
        margin-top: 30px;
        flex-direction: column;
    }

    .student-analysis-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .studentAnalysis-image {
        padding: 0;
    }

    .exam-analysis {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .exam-analysis-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .examAnalysis-image {
        padding: 0;
    }

    .question-bank {
        margin-top: 30px;
        flex-direction: column;
    }

    .question-bank-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .questionBank-image {
        padding: 0;
    }

    .benefits-container3 {
        flex-direction: column;
        padding: 20px;
    }

    .quickBenefits3 {
        padding: 20px;
    }

    .assessment {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .environment {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .evaluation {
        padding: 10px;
        border-right: none;
        width: 100%;
    }

}

.hostelView {
    padding: 50px;
}

.hostelView-img {
    width: 100%;
}

.hostelAdmission {
    display: flex;
    margin-top: 30px;
}

.hostelAdmission-text > h5 {
    font-weight: bold;
}

.hostelAdmission-img {
    width: 100%;
}

.manageRoom {
    padding: 30px;
    display: flex;
}

.manageRoom-text > h5 {
    font-weight: bold;
}

.manageRoom-img {
    width: 100%;
}

.mess {
    padding: 30px;
    display: flex;
    background-color: #F2F8FF;
}

.mess-text > h5 {
    font-weight: bold;
}

.mess-img {
    width: 100%;
}

.mundaneTask {
    display: flex;
    flex-direction: column;
    padding: 50px;
    text-align: center;
    width: 33%;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.mundaneTask > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.mundaneTask > p {
    text-align: justify;
}

.mundaneTask-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.easyPayment {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.easyPayment > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.easyPayment > p {
    text-align: justify;
}

.easyPayment-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.reliableProcess {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.reliableProcess > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.reliableProcess > p {
    text-align: justify;
}

.reliableProcess-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.digitalizationProcess {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.digitalizationProcess > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.digitalizationProcess > p {
    text-align: justify;
}

.digitalizationProcess-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.administration {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.administration > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.administration > p {
    text-align: justify;
}

.administration-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.smartOperation {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.smartOperation > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.smartOperation > p {
    text-align: justify;
}

.smartOperation-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 540px) {
    .hostelView {
        padding: 20px;
    }

    .hostelAdmission {
        flex-direction: column;
    }

    .hostelAdmission-text {
        padding: 0;
    }
    
    .hostelAdmission-image {
        padding: 0;
    }

    .manageRoom {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .manageRoom-text {
        padding: 0;
    }

    .manageRoom-image {
        padding: 0;
    }

    .mess {
        flex-direction: column;
        padding: 20px;
    }

    .mess-image {
        padding: 0;
    }

    .mess-text {
        padding: 0;
    }

    .mundaneTask {
        width: 100%;
        margin-right: 0;
    }

    .easyPayment {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .reliableProcess {
        width: 100%;
        margin-left: 0;
    }

    .digitalizationProcess {
        width: 100%;
        margin-right: 0;
    }

    .administration {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .smartOperation {
        width: 100%;
        margin-left: 0;
    }

}
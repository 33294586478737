
.noticeView {
    padding: 100px;
}

.noticeView-img {
    width: 100%;
}

.manage-notice {
    display: flex;
    margin-top: 30px;
}

.manage-notice-text > h5 {
    font-weight: bold;
}

.manageNotice-img {
    width: 100%;
}

.manage-category {
    padding: 30px;
    display: flex;
    background-color: #F2F8FF;
}

.manage-category-text > h5 {
    font-weight: bold;
}

.manageCategory-img {
    width: 100%;
}

.go-paperless {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.go-paperless > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.go-paperless > p {
    text-align: justify;
}

.go-paperless-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.onGoUpgrade {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.onGoUpgrade > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.onGoUpgrade > p {
    text-align: justify;
}

.onGoUpgrade-img {
    width: 40px;
    margin-bottom: 10px;
}

.broadcast {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.broadcast > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.broadcast > p {
    text-align: justify;
}

.broadcast-img {
    width: 40px;
    margin-bottom: 10px;
}

.efficientCost {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.efficientCost-text > h5 {
    font-weight: bold;
}

.efficientCost-img {
    height: 40px;
    margin-right: 10px;
}

.digitalization {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.digitalization-text > h5 {
    font-weight: bold;
}

.digitalization-img {
    height: 40px;
    margin-right: 10px;
}

.insight {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.insight-text > h5 {
    font-weight: bold;
}

.insight-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {
    .noticeView {
        padding: 20px;
    }

    .manage-notice {
        flex-direction: column;
    }

    .manage-notice-text {
        padding: 0;
    }
    
    .manageNotice-image {
        padding: 0;
    }

    .manage-category {
        flex-direction: column;
        padding: 20px;
    }

    .manage-category-text {
        padding: 0;
    }

    .manageCategory-image {
        padding: 0;
    }

    .go-paperless {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .onGoUpgrade {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .broadcast {
        padding: 10px;
        border-right: none;
        width: 100%;
    }

}
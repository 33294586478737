
.caseStudy-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.educationWork {
    display: flex;
    padding: 20px 30px;
}

.educationWork-text {
    display: flex;
    align-items: center;
}

.educationWork-text > h1 {
    font-weight: bold;
}

.educationWork-img {
    width: 60%;
}

.help {
    display: flex;
    padding: 20px 30px;
}

.help-text {
    display: flex;
    align-items: center;
}

.help-text > h1 {
    font-weight: bold;
}

.help-img {
    width: 60%;
}

.caseStudy-Info {
    padding: 20px 30px;
}

.caseStudy-details {
    padding: 20px 30px;
}

.enquiry-message {
    padding: 20px 30px;
}

.caseStudy-enquiry-container {
    /* background: radial-gradient(100% 100% at 50% 0%, #17BCD3 0%, rgba(49, 145, 186, 0.939236) 31.42%, rgba(95, 6, 241, 0.5) 100%); */
    padding: 40px 350px;
    text-align: center;
}

.caseStudy-enquiry-form {
    padding: 40px;
}

.caseStudy-enquiry-form input {
    background-color: transparent;
    color: #FFFFFF;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
}

.caseStudy-enquiry-form input:focus {
    background-color: transparent;
    color: #FFFFFF;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
    box-shadow: none;
}

.caseStudy-enquiry-form input::placeholder {
    color: #FFFFFF;
    font-size: 14px;
}

.submit-caseStudy {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 6px 12px;
    color: #3C0C89;
}

.teamMessage {
    padding: 30px;
    text-align: center;
}

@media only screen and (max-width: 540px) {

    .caseStudy-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .educationWork {
        padding: 20px;
        flex-direction: column;
        text-align: center;
    }

    .educationWork-text {
        padding: 0;
    }

    .educationWork-text > h1 {
        font-size: 22px;
    }

    .educationWork-image {
        padding: 0;
    }
    
    .help {
        padding: 20px;
        flex-direction: column-reverse;
        text-align: center;
    }

    .help-text {
        padding: 0;
    }

    .help-text > h1 {
        font-size: 22px;
    }

    .help-image {
        padding: 0;
    }

    .caseStudy-enquiry-container {
        padding: 20px;
    }
}
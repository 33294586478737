
.trainingView {
    padding: 100px;
}

.trainingView-img {
    /* width: 100%; */
    max-height: 500px;
}

.openings-drives {
    display: flex;
    margin-top: 30px;
}

.openings-drives-text > h5 {
    font-weight: bold;
}

.opening-img {
    width: 100%;
}

.recruitment-process {
    margin-top: 30px;
}

.recruitment-image {
    padding: 100px;
}

.recruitment-img {
    max-height: 500px;
}

.placement-brochure {
    padding: 30px;
    display: flex;
    background-color: #F2F8FF;
}

.placement-brochure-text > h5 {
    font-weight: bold;
}

.placementBrochure-img {
    width: 100%;
}

.training {
    padding: 30px;
    display: flex;
}

.training-text > h5 {
    font-weight: bold;
}

.training-img {
    width: 100%;
}

.training-photo {
    padding: 30px;
    display: flex;
    background-color: #F2F8FF;
}

.training-photo-text > h5 {
    font-weight: bold;
}

.trainingPhoto-img {
    width: 100%;
}

.support-container1 {
    padding: 20px 30px;
    display: flex;
}

@media only screen and (max-width: 540px) {
    .trainingView {
        padding: 20px;
    }
    .trainingView-img {
        width: 100%;
        max-height: 800px;
    }
    .recruitment-img {
        max-height: 500px;
        width: 100%;
    }
    .trainingView-img {
        height: auto;
    }

    .openings-drives {
        flex-direction: column;
    }

    .openings-drives-text {
        padding: 0;
    }
    
    .opening-image {
        padding: 0;
    }

    .recruitment-process {
        margin-top: 0;
    }
    
    .recruitment-image {
        padding: 20px;
    }

    .placement-brochure {
        flex-direction: column;
        padding: 20px;
    }

    .placement-brochure-text {
        padding: 0;
    }

    .placementBrochure-image {
        padding: 0;
    }

    .training {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .training-image {
        padding: 0;
    }

    .training-text {
        padding: 0;
    }

    .training-photo {
        flex-direction: column;
        padding: 20px;
    }

    .training-photo-text {
        padding: 0;
    }

    .trainingPhoto-image {
        padding: 0;
    }

    .support-container1 {
        flex-direction: column;
        padding: 20px;
    }

}

.inventoryView {
    padding: 100px;
}

.inventoryView-img {
    width: 100%;
    height: 800px;
}

.features-container2 {
    background-color: #F2F8FF;
    padding: 30px;
}

.manage-stock {
    display: flex;
    margin-top: 70px;
}

.manage-stock-text > h5 {
    font-weight: bold;
}

.manageStock-img {
    width: 100%;
}

.asset-management {
    display: flex;
    margin-top: 70px;
}

.asset-management-text > h5 {
    font-weight: bold;
}

.assetManagement-img {
    width: 100%;
}

.general-purchase {
    display: flex;
    margin-top: 70px;
}

.general-purchase-text > h5 {
    font-weight: bold;
}

.generalPurchase-img {
    width: 100%;
}

.purchase-request {
    display: flex;
    margin-top: 70px;
}

.purchase-request-text > h5 {
    font-weight: bold;
}

.purchaseRequest-img {
    width: 100%;
}

.indents {
    display: flex;
    margin-top: 70px;
}

.indents-text > h5 {
    font-weight: bold;
}

.indents-img {
    width: 100%;
}

.purchase-order {
    display: flex;
    margin-top: 70px;
}

.purchase-order-text > h5 {
    font-weight: bold;
}

.purchaseOrder-img {
    width: 100%;
}

.benefits-container1 {
    padding: 30px;
    background-color: #FFF6F9;
}

.benefits-info {
    display: flex;
}

.quickBenefits1-list {
    display: flex;
}

.quickBenefits1-header {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 20px;
    background: linear-gradient(90deg, #AB4357 0%, rgba(169, 60, 81, 0.915182) 30.38%, rgba(160, 31, 55, 0.551215) 72.57%, rgba(158, 26, 52, 0.5) 100%);
    border-radius: 4px;
}

.quickBenefits1-header > h3 {
    color: #FFFFFF;
    font-weight: bold;
}

.consumption {
    display: flex;
    flex-direction: column;
    padding: 50px;
    text-align: center;
    width: 33%;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.consumption > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.consumption > p {
    text-align: justify;
}

.consumption-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.import {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.import > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.import > p {
    text-align: justify;
}

.import-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.breakage {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.breakage > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.breakage > p {
    text-align: justify;
}

.breakage-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.longTerm-benefits1 {
    margin-top: 50px;
}

.longTerm-benefits1-header {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 20px;
    background: linear-gradient(90deg, #AB4357 0%, rgba(169, 60, 81, 0.915182) 30.38%, rgba(160, 31, 55, 0.551215) 72.57%, rgba(158, 26, 52, 0.5) 100%);
    border-radius: 4px;
}

.longTerm-benefits1-header > h3 {
    font-weight: bold;
    color: #FFFFFF;
}

.longBenefits1-list {
    display: flex;
}

.valueSaving {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.valueSaving > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.valueSaving > p {
    text-align: justify;
}

.valueSaving-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.lowStock {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.lowStock > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.lowStock > p {
    text-align: justify;
}

.lowStock-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.precisePlanning {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.precisePlanning > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.precisePlanning > p {
    text-align: justify;
}

.precisePlanning-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 540px) {
    .inventoryView {
        padding: 20px;
    }

    .inventoryView-img {
        height: auto;
    }

    .features-container2 {
        padding: 20px;
    }

    .manage-stock {
        margin-top: 30px;
        flex-direction: column;
    }

    .manage-stock-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .manageStock-image {
        padding: 0;
    }

    .asset-management {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .asset-management-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .assetManagement-image {
        padding: 0;
    }

    .general-purchase {
        margin-top: 30px;
        flex-direction: column;
    }

    .general-purchase-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .generalPurchase-image {
        padding: 0;
    }

    .purchase-request {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .purchase-request-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .purchaseRequest-image {
        padding: 0;
    }

    .indents {
        margin-top: 30px;
        flex-direction: column;
    }

    .indents-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .indents-image {
        padding: 0;
    }

    .purchase-order {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .purchase-order-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .purchaseOrder-image {
        padding: 0;
    }

    .benefits-container1 {
        padding: 20px;
    }

    .benefits-info {
        flex-direction: column;
    }

    .benefits-header > h3 {
        font-size: 24px;
    }

    .benefits-data {
        margin-top: 30px;
    }

    .quickBenefits1-header {
        width: 100%;
    }

    .quickBenefits1-header > h3 {
        font-size: 24px;
    }

    .quickBenefits1-list {
        flex-direction: column;
    }

    .consumption {
        width: 100%;
        margin-right: 0;
    }

    .import {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .breakage {
        width: 100%;
        margin-left: 0;
    }

    .longTerm-benefits1-header {
        width: 100%;
    }

    .longTerm-benefits1-header > h3 {
        font-size: 24px;
    }

    .longBenefits1-list {
        flex-direction: column;
    }

    .valueSaving {
        width: 100%;
        margin-right: 0;
    }

    .lowStock {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .precisePlanning {
        width: 100%;
        margin-left: 0;
    }
}




.webinar-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.webinar-message {
    padding: 20px 150px;
}

.webinar-message > h4 {
    text-align: justify;
}

.team-name {
    text-align: right;
    margin-top: 20px;
}

.webinar-enquiry-container {
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 100px 30px;
}

.webinar-enquiry-msg {
    padding: 30px;
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    color: #FFFFFF;
    margin-top: 50PX;
    margin-bottom: 100PX;
}

.webinar-enquiry-msg > h5 {
    margin-bottom: 0 !important;
}

.webinar-enquiry-form {
    /* padding: 130px 60px; */
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid #FFFFFF;
    /* backdrop-filter: blur(10px); */
    border-radius: 20px;
    /* margin-bottom: 200px; */
    justify-content: center;
}

.webinar-enquiry-form input {
    background-color: transparent;
    color: #FFFFFF;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
}

.webinar-enquiry-form input:focus {
    background-color: transparent;
    color: #FFFFFF;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
    box-shadow: none;
}

.webinar-enquiry-form input::placeholder {
    color: #FFFFFF;
    font-size: 14px;
}

.submit-webinar {
    background-color: #684C32;
    border-radius: 10px;
    padding: 6px 12px;
    color: #FFFFFF;
}

.submit-webinar:hover {
    color: #FFFFFF;
}

@media only screen and (max-width: 540px) {

    .webinar-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }
    
    .webinar-message {
        padding: 20px;
    }

    .webinar-enquiry-container {
        flex-direction: column;
        background-size: 100% 100%;
        padding: 20px;
    }

    .webinar-enquiry-msg {
        margin-top: 20px;
        margin-bottom: 30px;
        padding: 20px;
        text-align: center;
    }

    .webinar-enquiry-msg > h5 {
        font-size: 16px;
    }

    .webinar-enquiry-form {
        padding: 30px 20px;
        margin-bottom: 30px;
    }
}
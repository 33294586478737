
.footer-container {
    background-color: #F7F7F7;
}

.footer-links {
    margin-top: 50px;
    padding: 100px 30px 30px 30px;
    display: flex;
}

.aboutUs > a:hover {
    color: #007bff !important;
}

.connectUs > a:hover {
    color: #007bff !important;
}

.content > a:hover {
    color: #007bff !important;
}

.social-media {
    display: flex;
}

.social-img {
    height: 30px;
    margin-right: 30px;
}

.contact-details {
    padding: 30px;
    display: flex;
    font-weight: bold;
    background-color: #ffff;
}

.rights-reserved {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sendEnquiry {
    text-align: right;
}

.send-enquiry-button {
    color: #FFFFFF;
    background-color: #1C39BB;
    font-size: 16px !important;
}

.send-enquiry-button:hover {
    color: #FFFFFF;
    background-color: #1C39BB;
}

@media only screen and (max-width: 540px) {

    .footer-links {
        flex-direction: column;
        padding: 20px;
    }

    .aboutUs > h3 {
        font-size: 20px;
        margin-bottom: 10px !important;
    }

    .aboutUs > h5 {
        font-size: 16px;
    }

    .aboutUs {
        margin-bottom: 20px;
        padding: 0;
    }

    .connectUs > h3 {
        font-size: 20px;
        margin-bottom: 10px !important;
    }

    .connectUs > h5 {
        font-size: 16px;
    }

    .connectUs {
        margin-bottom: 20px;
        padding: 0;
    }

    .content > h3 {
        font-size: 20px;
        margin-bottom: 10px !important;
    }

    .content > h5 {
        font-size: 16px;
    }
    
    .content {
        margin-bottom: 20px;
        padding: 0;
    }
    
    .socialMedia > h3 {
        font-size: 20px;
        margin-bottom: 10px !important;
    }

    .socialMedia > h5 {
        font-size: 16px;
    }

    .socialMedia {
        margin-bottom: 20px;
        padding: 0;
    }

    .contact-details {
        flex-direction: column;
        padding: 20px;
        background-color: #ffff;
    }

    .rights-reserved {
        flex-direction: column;
        padding: 20px;
        text-align: center;
    }

    .rights > h5 {
        font-size: 16px;
    }

    .sendEnquiry {
        text-align: center;
    }

    .send-enquiry-button {
        font-size: 14px !important;
        padding: 7px 10px !important;
    }
}
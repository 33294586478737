
.eventView {
    padding: 100px;
}

.eventView-img {
    width: 100%;
}

.plan-event {
    display: flex;
    margin-top: 70px;
}

.plan-event-text > h5 {
    font-weight: bold;
}

.planEvent-img {
    width: 100%;
}

.manage-attendees {
    display: flex;
    margin-top: 70px;
}

.manage-attendees-text > h5 {
    font-weight: bold;
}

.manageAttendees-img {
    width: 100%;
}

.event-memories {
    display: flex;
    margin-top: 70px;
}

.event-memories-text > h5 {
    font-weight: bold;
}

.eventMemories-img {
    width: 100%;
}

.attendee-feedback {
    display: flex;
    margin-top: 70px;
}

.attendee-feedback-text > h5 {
    font-weight: bold;
}

.attendeeFeedback-img {
    width: 100%;
}

.generate-event {
    display: flex;
    margin-top: 70px;
}

.generate-event-text > h5 {
    font-weight: bold;
}

.generateEvent-img {
    width: 100%;
}

.simpleProcess {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.simpleProcess > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.simpleProcess > p {
    text-align: justify;
}

.simpleProcess-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.multipleReport {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.multipleReport > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.multipleReport > p {
    text-align: justify;
}

.multipleReport-img {
    width: 40px;
    margin-bottom: 10px;
}

.eventHub {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.eventHub > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.eventHub > p {
    text-align: justify;
}

.eventHub-img {
    width: 40px;
    margin-bottom: 10px;
}

.holisticApproach {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.holisticApproach-text > h5 {
    font-weight: bold;
}

.holisticApproach-img {
    height: 40px;
    margin-right: 10px;
}

.flexibility {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.flexibility-text > h5 {
    font-weight: bold;
}

.flexibility-img {
    height: 40px;
    margin-right: 10px;
}

.digitalRecord {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.digitalRecord-text > h5 {
    font-weight: bold;
}

.digitalRecord-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {
    .eventView {
        padding: 20px;
    }

    .plan-event {
        margin-top: 30px;
        flex-direction: column;
    }

    .plan-event-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .planEvent-image {
        padding: 0;
    }

    .manage-attendees {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .manage-attendees-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .manageAttendees-image {
        padding: 0;
    }

    .event-memories {
        margin-top: 30px;
        flex-direction: column;
    }

    .event-memories-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .eventMemories-image {
        padding: 0;
    }

    .attendee-feedback {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .attendee-feedback-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .attendeeFeedback-image {
        padding: 0;
    }

    .generate-event {
        margin-top: 30px;
        flex-direction: column;
    }

    .generate-event-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .generateEvent-image {
        padding: 0;
    }

    .simpleProcess {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .multipleReport {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .eventHub {
        padding: 10px;
        border-right: none;
        width: 100%;
    }

}

.sales-enquiry-form {
    width: 610px;
    height: 650px;
    border: 1px solid lightgray;
}

@media only screen and (max-width: 540px) {
    .imgContact {
        vertical-align: middle;
        border-style: none;
        padding-top: 24%;
    }

    .sales-enquiry-form {
        width: 100%;
    }

    .sales-email {
        font-size: 16px;
    }

    .enquiryData {
        padding: 0;
    }
}

.feesView {
    padding: 100px;
}

.feesView-img {
    width: 100%;
}

.quick-collect {
    display: flex;
    margin-top: 70px;
}

.quick-collect-text > h5 {
    font-weight: bold;
}

.quickCollect-img {
    width: 100%;
}

.fees-collect {
    display: flex;
    margin-top: 70px;
}

.fees-collect-text > h5 {
    font-weight: bold;
}

.feesCollect-img {
    width: 100%;
}

.fees-challan {
    display: flex;
    margin-top: 70px;
}

.fees-challan-text > h5 {
    font-weight: bold;
}

.feesChallan-img {
    width: 100%;
}

.fee-receipt {
    display: flex;
    margin-top: 70px;
}

.fee-receipt-text > h5 {
    font-weight: bold;
}

.feeReceipt-img {
    width: 100%;
}

.payment-gateway {
    display: flex;
    margin-top: 70px;
}

.payment-gateway-text > h5 {
    font-weight: bold;
}

.paymentGateway-img {
    /* width: 100%; */
    max-height: 500px;
}

.feeCollection {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.feeCollection > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.feeCollection > p {
    text-align: justify;
}

.feeCollection-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.multipleFees {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.multipleFees > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.multipleFees > p {
    text-align: justify;
}

.multipleFees-img {
    width: 40px;
    margin-bottom: 10px;
}

.gatewayPayment {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.gatewayPayment > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.gatewayPayment > p {
    text-align: justify;
}

.gatewayPayment-img {
    width: 40px;
    margin-bottom: 10px;
}

/* .depth-analysis {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.depth-analysis-text > h5 {
    font-weight: bold;
}

.depth-analysis-img {
    height: 40px;
    margin-right: 10px;
}

.automateUpdate {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.automateUpdate-text > h5 {
    font-weight: bold;
}

.automateUpdate-img {
    height: 40px;
    margin-right: 10px;
}

.dataLoss {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.dataLoss-text > h5 {
    font-weight: bold;
}

.dataLoss-img {
    height: 40px;
    margin-right: 10px;
} */

@media only screen and (max-width: 540px) {
    .feesView {
        padding: 20px;
    }
    .fees-img {
        width: 100%;
        max-height: 450px;
    }
    .paymentGateway-img {
        width: 100%;
        max-height: 500px;
    }
    .inventory-img {
        width: 100%;
        max-height: 450px;
    }
    .quick-collect {
        margin-top: 30px;
        flex-direction: column;
    }

    .quick-collect-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .quickCollect-image {
        padding: 0;
    }

    .fees-collect {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .fees-collect-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .feesCollect-image {
        padding: 0;
    }

    .fees-challan {
        margin-top: 30px;
        flex-direction: column;
    }

    .fees-challan-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .feesChallan-image {
        padding: 0;
    }

    .fee-receipt {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .fee-receipt-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .feeReceipt-image {
        padding: 0;
    }

    .payment-gateway {
        margin-top: 30px;
        flex-direction: column;
    }

    .payment-gateway-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .paymentGateway-image {
        padding: 0;
    }
    
    .feeCollection {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .multipleFees {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .gatewayPayment {
        padding: 10px;
        border-right: none;
        width: 100%;
    }

}
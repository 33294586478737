
.employeeView {
    padding: 100px;
}

.employeeView-img {
    width: 100%;
}

.employee-registration {
    display: flex;
    margin-top: 70px;
}

.employee-registration-text > h5 {
    font-weight: bold;
}

.employeeRegistration-img {
    /* width: 100%; */
    max-height: 500px;
}

.manage-employee {
    display: flex;
    margin-top: 70px;
}

.manage-employee-text > h5 {
    font-weight: bold;
}

.manageEmployee-img {
    width: 100%;
}

.information {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.information > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.information > p {
    text-align: justify;
}

.information-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.accessDocuments {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.accessDocuments > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.accessDocuments > p {
    text-align: justify;
}

.accessDocuments-img {
    width: 40px;
    margin-bottom: 10px;
}

.automatedProcess {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.automatedProcess > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.automatedProcess > p {
    text-align: justify;
}

.automatedProcess-img {
    width: 40px;
    margin-bottom: 10px;
}

.accessEmployee {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.accessEmployee-text > h5 {
    font-weight: bold;
}

.accessEmployee-img {
    height: 40px;
    margin-right: 10px;
}
.mobileViewShow{
    display: none
}
.deskTopViewShow{
    display: block
}

@media only screen and (max-width: 540px) {
    .employeeView {
        padding: 20px;
    }
    .mobileViewShow{
        display: block
    }
    .employeeRegistration-img {
        width: 100%;
        max-height: 500px;
    }
    .deskTopViewShow{
        display: none
    }

    .employee-registration {
        margin-top: 30px;
        flex-direction: column;
    }

    .employee-registration-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .employeeRegistration-image {
        padding: 0;
    }

    .manage-employee {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .manage-employee-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .manageEmployee-image {
        padding: 0;
    }

    .information {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .accessDocuments {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .automatedProcess {
        padding: 10px;
        width: 100%;
        border-right: none;
    }

}

.outcome-container {
    padding-top: 100px;
}

.outcome-Header {
    padding: 30px;
}

.outcome-Header > h2 {
    font-weight: bold;
}

.outcome-data {
    padding: 30px;
    background-color: #FFF9F9;
}

.outcomeData-header > a > h3 {
    font-weight: bold;
}

.outcomeData-header > a:hover {
    color: #007bff !important;
}

.outcome-info {
    display: flex;
    margin-top: 40px;
}

.outcome-text {
    display: flex;
    align-items: center;
}

.outcome-text > p {
    text-align: justify;
}

.outcome-img {
    width: 100%;
}

.academic-planning {
    padding: 30px;
}

.academicPlanning-header > a > h3 {
    font-weight: bold;
}

.academicPlanning-header > a:hover {
    color: #007bff !important;
}

.planning-info {
    display: flex;
    margin-top: 40px;
}

.planning-text {
    display: flex;
    align-items: center;
}

.planning-text > p {
    text-align: justify;
}

.planning-img {
    width: 100%;
}

.online-feedback {
    padding: 30px;
    background-color: #FFF9F9;
}

.onlineFeedback-header > a > h3 {
    font-weight: bold;
}

.onlineFeedback-header > a:hover {
    color: #007bff !important;
}

.feedback-info {
    display: flex;
    margin-top: 40px;
}

.feedback-text {
    display: flex;
    align-items: center;
}

.feedback-text > p {
    text-align: justify;
}

.feedback-img {
    width: 100%;
}

.online-exam {
    padding: 30px;
}

.onlineExam-header > a > h3 {
    font-weight: bold;
}

.onlineExam-header > a:hover {
    color: #007bff !important;
}

.exam-info {
    display: flex;
    margin-top: 40px;
}

.exam-text {
    display: flex;
    align-items: center;
}

.exam-text > p {
    text-align: justify;
}

.exam-img {
    width: 100%;
}

.result-analysis {
    padding: 30px;
    background-color: #FFF9F9;
}

.resultAnalysis-header > a > h3 {
    font-weight: bold;
}

.resultAnalysis-header > a:hover {
    color: #007bff !important;
}

.result-info {
    display: flex;
    margin-top: 40px;
}

.result-text {
    display: flex;
    align-items: center;
}

.result-text > p {
    text-align: justify;
}

.result-img {
    width: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.rubrics-projects {
    padding: 30px;
}

.rubrics-header > a > h3 {
    font-weight: bold;
}

.rubrics-header > a:hover {
    color: #007bff !important;
}

.rubrics-info {
    display: flex;
    margin-top: 40px;
}

.rubrics-text {
    display: flex;
    align-items: center;
}

.rubrics-text > p {
    text-align: justify;
}

.rubrics-img {
    width: 100%;
}

.assignment {
    padding: 30px;
    background-color: #FFF9F9;
}

.assignment-header > a > h3 {
    font-weight: bold;
}

.assignment-header > a:hover {
    color: #007bff !important;
}

.assignment-info {
    display: flex;
    margin-top: 40px;
}

.assignment-text {
    display: flex;
    align-items: center;
}

.assignment-text > p {
    text-align: justify;
}

.assignment-img {
    width: 100%;
}

.IQAC {
    padding: 30px;
}

.IQAC-header > a > h3 {
    font-weight: bold;
}

.IQAC-header > a:hover {
    color: #007bff !important;
}

.IQAC-info {
    display: flex;
    margin-top: 40px;
}

.IQAC-text {
    display: flex;
    align-items: center;
}

.IQAC-text > p {
    text-align: justify;
}

.IQAC-img {
    width: 100%;
}

@media only screen and (max-width: 540px) {
    .outcome-container {
        padding-top: 80px;
    }

    .outcome-Header > h2 {
        font-size: 26px;
        text-align: center;
    }

    .outcome-data {
        padding: 20px;
    }

    .outcomeData-header > a > h3 {
        font-size: 24px;
    }

    .outcome-info {
        flex-direction: column;
    }

    .outcome-text {
        padding: 0;
    }

    .outcome-image {
        padding: 0;
    }

    .academic-planning {
        padding: 20px;
    }

    .academicPlanning-header > a > h3 {
        font-size: 24px;
    }

    .planning-info {
        flex-direction: column-reverse;
    }

    .planning-image {
        padding: 0;
    }

    .planning-text {
        padding: 0;
    }

    .online-feedback {
        padding: 20px;
    }
    
    .onlineFeedback-header > a > h3 {
        font-size: 24px;
    }

    .feedback-info {
        flex-direction: column;
    }

    .feedback-text {
        padding: 0;
    }

    .feedback-image {
        padding: 0;
    }

    .online-exam {
        padding: 20px;
    }
    
    .onlineExam-header > a > h3 {
        font-size: 24px;
    }

    .exam-info {
        flex-direction: column-reverse;
    }

    .exam-text {
        padding: 0;
    }

    .exam-image {
        padding: 0;
    }

    .result-analysis {
        padding: 20px;
    }
    
    .resultAnalysis-header > a > h3 {
        font-size: 24px;
    }

    .result-info {
        flex-direction: column;
    }

    .result-text {
        padding: 0;
    }

    .result-image {
        padding: 0;
    }

    .rubrics {
        padding: 20px;
    }
    
    .rubrics-header > a > h3 {
        font-size: 24px;
    }

    .rubrics-info {
        flex-direction: column-reverse;
    }

    .rubrics-text {
        padding: 0;
    }

    .rubrics-image {
        padding: 0;
    }

    .assignment {
        padding: 20px;
    }
    
    .assignment-header > a > h3 {
        font-size: 24px;
    }

    .assignment-info {
        flex-direction: column;
    }

    .assignment-text {
        padding: 0;
    }

    .assignment-image {
        padding: 0;
    }

    .IQAC {
        padding: 20px;
    }
    
    .IQAC-header > a > h3 {
        font-size: 24px;
    }

    .IQAC-info {
        flex-direction: column-reverse;
    }

    .IQAC-text {
        padding: 0;
    }

    .IQAC-image {
        padding: 0;
    }
}
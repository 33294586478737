
.rubricsView {
    padding: 100px;
}

.rubricsView-img {
    /* width: 100%; */
    max-height: 500px;
}

.generate-rubric {
    display: flex;
    margin-top: 70px;
}

.generate-rubric-text > h5 {
    font-weight: bold;
}

.generateRubric-img {
    width: 100%;
}

.add-project {
    display: flex;
    margin-top: 70px;
}

.add-project-text > h5 {
    font-weight: bold;
}

.addProject-img {
    /* width: 100%; */
    max-height: 500px;
}

.apply-rubrics {
    display: flex;
    margin-top: 70px;
}

.apply-rubrics-text > h5 {
    font-weight: bold;
}

.applyRubrics-img {
    width: 100%;
}

.comparison {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.comparison > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.comparison > p {
    text-align: justify;
}

.comparison-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.precision {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.precision > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.precision > p {
    text-align: justify;
}

.precision-img {
    width: 40px;
    margin-bottom: 10px;
}

.accurate-assessment {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.accurate-assessment > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.accurate-assessment > p {
    text-align: justify;
}

.accurateAssessment-img {
    width: 40px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 540px) {
    .rubricsView {
        padding: 20px;
    }
    .rubricsView-img {
        width: 100%;
        max-height: 500px;
    }
    
    .addProject-img {
        width: 100%;
        max-height: 500px;
    }
    
    .generate-rubric {
        margin-top: 30px;
        flex-direction: column;
    }

    .generate-rubric-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .generateRubric-image {
        padding: 0;
    }

    .add-project {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .add-project-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .addProject-image {
        padding: 0;
    }

    .apply-rubrics {
        margin-top: 30px;
        flex-direction: column;
    }

    .apply-rubrics-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .applyRubrics-image {
        padding: 0;
    }
    
    .comparison {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .precision {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .accurate-assessment {
        padding: 10px;
        border-right: none;
        width: 100%;
    }

}

.committeeView {
    padding: 100px;
}

.committeeView-img {
    width: 100%;
}

.committee {
    display: flex;
    margin-top: 70px;
}

.committee-text > h5 {
    font-weight: bold;
}

.committee-img {
    width: 100%;
}

.meetingManagement {
    display: flex;
    margin-top: 70px;
}

.meetingManagement-text > h5 {
    font-weight: bold;
}

.meeting-img {
    width: 100%;
}

.mom {
    display: flex;
    margin-top: 70px;
}

.mom-text > h5 {
    font-weight: bold;
}

.mom-img {
    width: 100%;
}

.meeting-attendance {
    display: flex;
    margin-top: 70px;
}

.meeting-attendance-text > h5 {
    font-weight: bold;
}

.meetingAttendance-img {
    width: 100%;
}

.cooperation {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.cooperation > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.cooperation > p {
    text-align: justify;
}

.cooperation-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.powerDispersion {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.powerDispersion > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.powerDispersion > p {
    text-align: justify;
}

.powerDispersion-img {
    width: 40px;
    margin-bottom: 10px;
}

.maintainData {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.maintainData > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.maintainData > p {
    text-align: justify;
}

.maintainData-img {
    width: 40px;
    margin-bottom: 10px;
}

.onGoAccess {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.onGoAccess-text > h5 {
    font-weight: bold;
}

.onGoAccess-img {
    height: 40px;
    margin-right: 10px;
}

.preciseDecision {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.preciseDecision-text > h5 {
    font-weight: bold;
}

.preciseDecision-img {
    height: 40px;
    margin-right: 10px;
}

.workStructure {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.workStructure-text > h5 {
    font-weight: bold;
}

.workStructure-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {
    .committeeView {
        padding: 20px;
    }

    .committee {
        flex-direction: column;
    }
    
    .committee-text {
        padding: 0;
    }
    
    .committee-image {
        padding: 0;
    }

    .meetingManagement {
        flex-direction: column-reverse;
    }
    
    .meetingManagement-text {
        padding: 0;
    }
    
    .meeting-image {
        padding: 0;
    }

    .mom {
        flex-direction: column;
    }
    
    .mom-text {
        padding: 0;
    }
    
    .mom-image {
        padding: 0;
    }
    
    .meeting-attendance {
        flex-direction: column-reverse;
    }
    
    .meeting-attendance-text {
        padding: 0;
    }
    
    .meetingAttendance-image {
        padding: 0;
    }

    .cooperation {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .powerDispersion {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .maintainData {
        padding: 10px;
        width: 100%;
        border-right: none;
    }

}
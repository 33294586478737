
.payrollView {
    padding: 100px;
}

.payrollView-img {
    width: 100%;
}

.manage-employee {
    display: flex;
    margin-top: 70px;
}

.manage-employee-text > h5 {
    font-weight: bold;
}

.manageEmployee-img {
    width: 100%;
}

.custom-salary {
    display: flex;
    margin-top: 70px;
}

.custom-salary-text > h5 {
    font-weight: bold;
}

.customSalary-img {
    width: 100%;
}

.payrun {
    display: flex;
    margin-top: 70px;
}

.payrun-text > h5 {
    font-weight: bold;
}

.payrun-img {
    width: 100%;
}

.custom-template {
    display: flex;
    margin-top: 70px;
}

.custom-template-text > h5 {
    font-weight: bold;
}

.customTemplate-img {
    width: 100%;
}

.reliable {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.reliable > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.reliable > p {
    text-align: justify;
}

.reliable-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.processQuick {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.processQuick > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.processQuick > p {
    text-align: justify;
}

.processQuick-img {
    width: 40px;
    margin-bottom: 10px;
}

.monitor {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.monitor > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.monitor > p {
    text-align: justify;
}

.monitor-img {
    width: 40px;
    margin-bottom: 10px;
}

.onGoChanges {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.onGoChanges-text > h5 {
    font-weight: bold;
}

.onGoChanges-img {
    height: 40px;
    margin-right: 10px;
}

.workLoad {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.workLoad-text > h5 {
    font-weight: bold;
}

.workLoad-img {
    height: 40px;
    margin-right: 10px;
}

.futureOperation {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.futureOperation-text > h5 {
    font-weight: bold;
}

.futureOperation-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {
    .payrollView {
        padding: 20px;
    }

    .manage-employee {
        margin-top: 30px;
        flex-direction: column;
    }

    .manage-employee-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .manageEmployee-image {
        padding: 0;
    }

    .custom-salary {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .custom-salary-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .customSalary-image {
        padding: 0;
    }

    .payrun {
        margin-top: 30px;
        flex-direction: column;
    }

    .payrun-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .payrun-image {
        padding: 0;
    }

    .custom-template {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .custom-template-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .customTemplate-image {
        padding: 0;
    }

    .make-payment {
        margin-top: 30px;
        flex-direction: column;
    }

    .make-payment-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .makePayment-image {
        padding: 0;
    }
    
    .reliable {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .processQuick {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .monitor {
        padding: 10px;
        border-right: none;
        width: 100%;
    }

}
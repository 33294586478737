
.service-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.service-info {
    padding: 0px 30px;
}

.service-info > p {
    text-align: justify;
}

.eligibility {
    display: flex;
    padding: 0px 30px;
}

.term-number {
    margin-right: 10px;
}

.term-number > h6 {
    font-weight: bold;
}

.term-text > h6 {
    font-weight: bold;
}

.term-text > p {
    text-align: justify;
}

.accountCreation {
    display: flex;
    padding: 0px 30px;
}

.termination {
    display: flex;
    padding: 0px 30px;
}

.nonCommercial {
    display: flex;
    padding: 0px 30px;
}

.userResponsibility {
    display: flex;
    padding: 0px 30px;
}

.accountSecurity {
    display: flex;
    padding: 0px 30px;
}

.interaction {
    display: flex;
    padding: 0px 30px;
}

.proprietary {
    display: flex;
    padding: 0px 30px;
}

.contents {
    display: flex;
    padding: 0px 30px;
}

.paymentTaxes {
    display: flex;
    padding: 0px 30px;
}

.smsRates {
    display: flex;
    padding: 0px 30px;
}

.indemnity {
    display: flex;
    padding: 0px 30px;
}

.prohibited {
    display: flex;
    padding: 0px 30px;
}

.customerService {
    display: flex;
    padding: 0px 30px;
}

.serviceModification {
    display: flex;
    padding: 0px 30px;
}

.disclaimer {
    display: flex;
    padding: 0px 30px;
}

.liability {
    display: flex;
    padding: 0px 30px;
}

.jurisdiction {
    display: flex;
    padding: 0px 30px;
}

.notices {
    display: flex;
    padding: 0px 30px;
}

.provisions {
    display: flex;
    padding: 0px 30px;
}

.relationships {
    display: flex;
    padding: 0px 30px;
}

.contact-message {
    text-align: center;
}

@media only screen and (max-width: 540px) {

    .service-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }
    
    .service-info {
        padding: 0px 20px;
    }

    .eligibility {
        padding: 0px 20px;
    }

    .term-text > p {
        word-break: break-all;
    }

    .accountCreation {
        padding: 0px 20px;
    }

    .termination {
        padding: 0px 20px;
    }

    .nonCommercial {
        padding: 0px 20px;
    }

    .userResponsibility {
        padding: 0px 20px;
    }

    .accountSecurity {
        padding: 0px 20px;
    }

    .interaction {
        padding: 0px 20px;
    }

    .proprietary {
        padding: 0px 20px;
    }

    .contents {
        padding: 0px 20px;
    }

    .paymentTaxes {
        padding: 0px 20px;
    }

    .smsRates {
        padding: 0px 20px;
    }

    .indemnity {
        padding: 0px 20px;
    }

    .prohibited {
        padding: 0px 20px;
    }

    .customerService {
        padding: 0px 20px;
    }

    .serviceModification {
        padding: 0px 20px;
    }

    .disclaimer {
        padding: 0px 20px;
    }

    .liability {
        padding: 0px 20px;
    }

    .jurisdiction {
        padding: 0px 20px;
    }

    .notices {
        padding: 0px 20px;
    }

    .provisions {
        padding: 0px 20px;
    }

    .relationships {
        padding: 0px 20px;
    }
}

.knowledge-header {
    padding-top: 120px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.knowledge-message {
    text-align: center;
    padding: 30px 300px;
}

.knowledge-message > h3 {
    font-weight: bold;
    text-align: justify;
}

.enquiry-msg {
    padding: 30px;
}

.enquiry-msg > h6 {
    font-weight: bold;
}

.enquiry-form-container {
    padding: 20px 30px;
    /* background: radial-gradient(100% 518.32% at 0% 0%, #0648F1 0%, rgba(118, 8, 227, 0.957899) 16.84%, rgba(57, 146, 184, 0.825855) 69.66%, rgba(22, 159, 159, 0.75) 100%); */
}

.enquiry-form {
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid #FFFFFF;
    backdrop-filter: blur(20px);
    border-radius: 20px;
    padding: 40px;
}

.enquiry-form input {
    background-color: transparent;
    color: #FFFFFF;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
}

.enquiry-form input:focus {
    background-color: transparent;
    color: #FFFFFF;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
    box-shadow: none;
}

.enquiry-form input::placeholder {
    color: #FFFFFF;
    font-size: 14px;
}

.submit-knowledge {
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 6px 12px;
    color: #FFFFFF;
}

.submit-knowledge:hover {
    color: #FFFFFF;
}

@media only screen and (max-width: 540px) {

    .knowledge-header {
        padding-top: 100px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .knowledge-message {
        padding: 20px;
    }

    .knowledge-message > h3 {
        font-size: 22px;
    }

    .enquiry-msg {
        padding: 20px;
    }

    .enquiry-form-container {
        padding: 20px;
    }
}
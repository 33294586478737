
.supports-header {
    padding-top: 120px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
}

.support-message {
    text-align: center;
}

.support-message > h1 {
    background-image: linear-gradient(to right,#0A13F5,#F30D0D);
    font-weight: bold;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.success-container {
    padding: 30px;
}

.success-container > h5 {
    font-weight: bold;
}

.successDetails {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.ongoing-business {
    width: 30%;
    text-align: center;
    padding: 70px 20px;
    box-shadow: 0px 0px 10px rgba(255, 0, 0, 0.25);
    border-radius: 10px;
}

.onBusiness-img {
    height: 100px;
    margin-bottom: 40px;
}

.ongoing-business > p {
    text-align: justify;
}

.long-lasting {
    width: 30%;
    text-align: center;
    padding: 70px 20px;
    box-shadow: 0px 0px 10px rgba(20, 0, 255, 0.25);
    border-radius: 10px;
}

.longLasting-img {
    height: 100px;
    margin-bottom: 40px;
}

.long-lasting > p {
    text-align: justify;
}

.obsessed-culture {
    width: 30%;
    text-align: center;
    padding: 70px 20px;
    box-shadow: 0px 0px 10px rgba(255, 0, 229, 0.25);
    border-radius: 10px;
}

.obsessedCulture-img {
    height: 100px;
    margin-bottom: 40px;
}

.obsessed-culture > p {
    text-align: justify;
}

.vmedulife-message {
    background-color: #6D23E6;
    padding: 40px 200px;
    color: #FFFFFF;
}

.vmedulife-name {
    text-align: right;
    margin-top: 50px;
}

.successContact {
    text-align: center;
    /* margin-top: 70px; */
}

.successContact > h5 {
    font-weight: bold;
}

.contactUsButton {
    background: #6D23E6;
    color: #FFFFFF;
    padding: 6px 12px;
    box-shadow: 0px 0px 10px rgba(109, 35, 230, 0.5);
    border-radius: 10px;
    margin-top: 30px;
}

.contactUsButton:hover {
    background: #13B1F4;
    color: #FFFFFF;
}

@media only screen and (max-width: 540px) {

    .supports-header {
        padding-top: 100px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
    }

    .support-message {
        padding: 20px;
    }

    .support-message > h1 {
        font-size: 22px;
    }

    .successDetails {
        flex-direction: column;
    }

    .ongoing-business {
        width: 100%;
        margin-bottom: 20px;
    }

    .long-lasting {
        width: 100%;
        margin-bottom: 20px;
    }

    .obsessed-culture {
        width: 100%;
        margin-bottom: 20px;
    }

    .vmedulife-message {
        padding: 20px;
    }

    .vmedulife-message > h4 {
        font-size: 24px;
    }

    .vmedulife-name {
        margin-top: 20px;
    }

    .successContact {
        margin-top: 20px;
        padding: 20px;
    }
}
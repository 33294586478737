

.lmsView {
    padding: 100px;
}

.lmsView-img {
    width: 100%;
}

.course-creation {
    display: flex;
    margin-top: 40px;
    padding: 0px 30px;
}

.course-creation-text > h5 {
    font-weight: bold;
}

.courseCreation-img {
    width: 100%;
}

.course-collaboration {
    display: flex;
    margin-top: 40px;
    padding: 20px 30px;
    background-color: #F2F8FF;
}

.course-collaboration-text > h5 {
    font-weight: bold;
}

.courseCollaboration-img {
    /* width: 100%; */
    max-height: 375px;
}

.course-certificate {
    display: flex;
    margin-top: 40px;
    padding: 0px 30px;
}

.course-certificate-text > h5 {
    font-weight: bold;
}

.courseCertificate-img {
    /* width: 100%; */
    max-height: 375px;
}

.learningContent {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.learningContent > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.learningContent > p {
    text-align: justify;
}

.learningContent-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.unlimitedAccess {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #AB4357;
}

.unlimitedAccess > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.unlimitedAccess > p {
    text-align: justify;
}

.unlimitedAccess-img {
    width: 40px;
    margin-bottom: 10px;
}

.learnerProgress {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.learnerProgress > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.learnerProgress > p {
    text-align: justify;
}

.learnerProgress-img {
    width: 40px;
    margin-bottom: 10px;
}

.learningCost {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.learningCost-text > h5 {
    font-weight: bold;
}

.learningCost-img {
    height: 40px;
    margin-right: 10px;
}

.quickLearning {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.quickLearning-text > h5 {
    font-weight: bold;
}

.quickLearning-img {
    height: 35px;
    margin-right: 15px;
}

.socialLearning {
    display: flex;
    color: #000000;
    margin-top: 30px;
}

.socialLearning-text > h5 {
    font-weight: bold;
}

.socialLearning-img {
    height: 35px;
    margin-right: 15px;
}

@media only screen and (max-width: 540px) {

    .courseCertificate-img {
        width: 100%;
        max-height: 375px;
    }

    .courseCollaboration-img {
        width: 100%;
        max-height: 375px;
    }

    .lmsView {
        padding: 20px;
    }

    .course-creation {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .courseCreation-image {
        padding: 0;
    }

    .course-creation-text {
        padding: 0;
    }

    .course-collaboration {
        flex-direction: column;
        padding: 20px;
    }

    .courseCollaboration-image {
        padding: 0;
    }

    .course-collaboration-text {
        padding: 0;
    }

    .course-certificate {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .courseCertificate-image {
        padding: 0;
    }

    .course-certificate-text {
        padding: 0;
    }

    .learningContent {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .unlimitedAccess {
        padding: 10px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AB4357;
    }

    .learnerProgress {
        padding: 10px;
        width: 100%;
        border-right: none;
    }

}



.inwardView {
    padding: 100px;
}

.inwardView-img {
    width: 100%;
}

.inward-letter {
    display: flex;
    margin-top: 70px;
}

.inward-letter-text > h5 {
    font-weight: bold;
}

.inwardLetter-img {
    width: 100%;
}

.outward-letter {
    display: flex;
    margin-top: 70px;
}

.outward-letter-text > h5 {
    font-weight: bold;
}

.outwardLetter-img {
    width: 100%;
}

.secureEnd {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.secureEnd > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.secureEnd > p {
    text-align: justify;
}

.secureEnd-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.accessSystem {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
}

.accessSystem > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.accessSystem > p {
    text-align: justify;
}

.accessSystem-img {
    width: 40px;
    margin-bottom: 10px;
}

.useEasy {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    width: 33%;
    padding: 50px;
    text-align: center;
}

.useEasy > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.useEasy > p {
    text-align: justify;
}

.useEasy-img {
    width: 40px;
    margin-bottom: 10px;
}

.oneRoof {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.oneRoof-text > h5 {
    font-weight: bold;
}

.oneRoof-img {
    height: 40px;
    margin-right: 10px;
}

.digitalData {
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
}

.digitalData-text > h5 {
    font-weight: bold;
}

.digitalData-img {
    height: 40px;
    margin-right: 10px;
}

@media only screen and (max-width: 540px) {
    .inwardView {
        padding: 20px;
    }

    .inward-letter {
        margin-top: 30px;
        flex-direction: column;
    }

    .inward-letter-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .inwardLetter-image {
        padding: 0;
    }

    .outward-letter {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .outward-letter-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .outwardLetter-image {
        padding: 0;
    }

    .secureEnd {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .accessSystem {
        padding: 10px;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
    }
    
    .useEasy {
        padding: 10px;
        border-right: none;
        width: 100%;
    }

}

.assignmentView {
    padding: 100px;
}

.assignmentView-img {
    /* width: 100%; */
    max-height: 500px;
}

.plan-assignment {
    display: flex;
    margin-top: 70px;
}

.plan-assignment-text > h5 {
    font-weight: bold;
}

.planAssignment-img {
    width: 100%;
}

.add-question {
    display: flex;
    margin-top: 70px;
}

.add-question-text > h5 {
    font-weight: bold;
}

.addQuestion-img {
    width: 100%;
}

.assign-set {
    display: flex;
    margin-top: 70px;
}

.assign-set-text > h5 {
    font-weight: bold;
}

.assignSet-img {
    width: 100%;
}

.assignment-grading {
    display: flex;
    margin-top: 70px;
}

.assignment-grading-text > h5 {
    font-weight: bold;
}

.assignmentGrading-img {
    width: 100%;
}

.assignment-quality {
    display: flex;
    margin-top: 70px;
}

.assignment-quality-text > h5 {
    font-weight: bold;
}

.assignmentQuality-img {
    width: 100%;
}

.benefits-info {
    display: flex;
}

.submission {
    display: flex;
    flex-direction: column;
    padding: 50px;
    text-align: center;
    width: 33%;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.submission > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.submission > p {
    text-align: justify;
}

.submission-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.assignment-feedback {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.assignment-feedback > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.assignment-feedback > p {
    text-align: justify;
}

.assignment-feedback-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.goGreen {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.goGreen > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.goGreen > p {
    text-align: justify;
}

.goGreen-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.accessData {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 10px;
}

.accessData > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.accessData > p {
    text-align: justify;
}

.accessData-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.outcomeAssignment {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
}

.outcomeAssignment > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.outcomeAssignment > p {
    text-align: justify;
}

.outcomeAssignment-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.savePaper {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 33%;
    text-align: center;
    background-color: #FFFFFF;
    margin-top: 50px;
    margin-left: 10px;
}

.savePaper > h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.savePaper > p {
    text-align: justify;
}

.savePaper-img {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
    .assignmentView {
        padding: 20px;
    }

    .plan-assignment {
        margin-top: 30px;
        flex-direction: column;
    }

    .plan-assignment-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .planAssignment-image {
        padding: 0;
    }

    .add-question {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .add-question-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .addQuestion-image {
        padding: 0;
    }

    .assign-set {
        margin-top: 30px;
        flex-direction: column;
    }

    .assign-set-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .assignSet-image {
        padding: 0;
    }

    .assignment-grading {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .assignment-grading-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .assignmentGrading-image {
        padding: 0;
    }

    .assignment-quality {
        margin-top: 30px;
        flex-direction: column;
    }

    .assignment-quality-text {
        padding: 0;
        margin-bottom: 10px;
    }

    .assignmentQuality-image {
        padding: 0;
    }

    .benefits-info {
        flex-direction: column;
    }

    .benefits-header > h3 {
        font-size: 24px;
    }

    .benefits-data {
        margin-top: 30px;
    }

    .submission {
        width: 100%;
        margin-right: 0;
    }

    .assignment-feedback {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .goGreen {
        width: 100%;
        margin-left: 0;
    }

    .accessData {
        width: 100%;
        margin-right: 0;
    }

    .outcomeAssignment {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .savePaper {
        width: 100%;
        margin-left: 0;
    }
    .assignmentView-img {
        width: 100%;
        max-height: 500px;
    }
}


